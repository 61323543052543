import React, { ChangeEvent, CSSProperties, useEffect, useMemo, useState } from 'react';

import { Button, FormHelperText, Grid, LinearProgress, Typography, Autocomplete } from '@mui/material';
import MuiTextField from '@mui/material/TextField';

import * as yup from 'yup';
import intl from 'react-intl-universal';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import { AxiosError } from 'axios';
import ImageViewer from 'react-simple-image-viewer';

import { useToastify } from 'hooks/toastfy';

import { displayError } from 'helpers/http';

import api from 'shared/api';
import { initIntl } from 'shared/locales';
import { UTILS, ENDPOINTS, ROUTES } from 'shared/constants';

import School from 'models/School';

import Breadcrumbs from 'components/Breadcrumbs';
import { SelectOption } from 'components/Table/Filter';

const thumbStyle: CSSProperties = {
  borderRadius: '8px',
  cursor: 'pointer',
  marginTop: '16px',
  width: '300px',
  height: '300px',
  display: 'flex',
};

interface FormData {
  name: string;
  school_ids: SelectOption[];
  image: string;
}

initIntl();

const getValidation = (id: string | undefined) => yup.object({
  name: yup
    .string()
    .required(intl.get('validation.requiredField'))
    .max(UTILS.STRING_MAX_LEN),
  school_ids: yup
    .array()
    .nullable()
    .optional(),
  image: !id ? yup.mixed()
    .required(intl.get('validation.requiredField')) : yup.string().nullable().optional(),
});

/**
 * FormHighlight component
 * @return {JSX.Element}
 */
export default function FormHighlight(): JSX.Element {
  const navigate = useNavigate();
  const toast = useToastify();
  const { id } = useParams<{ id: string }>();
  const [schoolOptions, setSchoolOptions] = useState<SelectOption[]>([]);
  const [thumb, setThumb] = useState(UTILS.BLANK);
  const [openImage, setOpenImage] = useState(false);
  const [original, setOriginal] = useState(UTILS.BLANK);
  const [file, setFile] = useState<File>();
  const [initialValues, setInitialValues] = useState({
    name: UTILS.BLANK,
    school_ids: [],
    image: UTILS.BLANK,
  });

  const validationSchema = getValidation(id);

  useEffect(() => {
    getSchools();
    if (id) {
      getHighlight();
    }
  }, []);

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: intl.get('pages.highlights.title'),
        onClickCallback: () => navigate(ROUTES.HIGHLIGHTS),
      },
      {
        name: `${id ? intl.get('common.edit') : intl.get('common.add')}${UTILS.SPACE}${intl.get('pages.formHighlight.title')}`,
        onClickCallback: () => { },
      },
    ];
  }, [id]);

  const getSchools = async () => {
    const response = await api.get(ENDPOINTS.SCHOOLS.GET.ALL);
    const schools = response.data.map((school: School) => ({
      value: school.id,
      label: school.name,
    }));
    setSchoolOptions(schools);
  };

  const getHighlight = async () => {
    if (id) {
      const response = await api.get(ENDPOINTS.HIGHLIGHTS.GET.BY_ID.replace(':id', id));
      setInitialValues({
        name: response.data.name,
        school_ids: response.data.schools.map((school: School) => ({
          value: school.id,
          label: school.name,
        })),
        image: UTILS.BLANK,
      });
      if (response.data.path) {
        setOriginal(response.data.path);
        const parts = response.data.path.split('/');
        const fileName = parts.pop();
        setThumb(`${parts.join('/')}/thumb_${fileName}`);
      }
    }
  };

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    if (event.target.files) {
      const uploadedFile: File = event.target.files[0];
      setFieldValue('image', uploadedFile.name);
      event.target.value = UTILS.BLANK;
      const url = URL.createObjectURL(uploadedFile);
      setThumb(url);
      setOriginal(url);
      setFile(uploadedFile);
    }
  };

  const save = async (values: FormData) => {
    try {
      const FD = new FormData();
      if (file) {
        FD.append('image', file, file.name);
      }

      FD.append('name', values.name);

      values.school_ids.forEach((schoolIdOption) => {
        FD.append('school_ids[]', schoolIdOption.value);
      });

      if (id) {
        FD.append('_method', 'put');
        await api.post(ENDPOINTS.HIGHLIGHTS.PUT.BY_ID.replace(':id', id), FD);
      } else {
        await api.post(ENDPOINTS.HIGHLIGHTS.POST.ADD, FD);
      }
      toast.success(intl.get('toast.saveSuccess'));
    } catch (error) {
      setThumb(UTILS.BLANK);
      setOriginal(UTILS.BLANK);
      setFile(undefined);
      displayError(error as AxiosError);
    }
  };

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await save(values);
          setSubmitting(false);
          navigate(ROUTES.HIGHLIGHTS);
        }}
      >
        {({ submitForm, isSubmitting, setFieldValue, errors, values }) => (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                submitForm();
              }
            }}
          >
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    type="text"
                    label={intl.get('pages.formHighlight.name')}
                    name="name"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <Autocomplete
                    multiple
                    fullWidth
                    filterSelectedOptions
                    options={schoolOptions}
                    getOptionLabel={(option: SelectOption) => option.label}
                    value={values['school_ids']}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(e, value) => setFieldValue('school_ids', value)}
                    renderInput={(params) => (
                      <MuiTextField
                        error={!!errors['school_ids']}
                        helperText={!!errors['school_ids'] ? intl.get('validation.requiredField') : UTILS.BLANK}
                        {...params}
                        label={intl.get('pages.formHighlight.school')}
                        placeholder={intl.get('pages.formHighlight.schoolPlaceholder')}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <input
                    type="file"
                    disabled={isSubmitting}
                    name="image"
                    onChange={(e) => handleFileChange(e, setFieldValue)}
                  />
                  <Typography mt={1}>{intl.get('pages.formHighlight.acceptedFiles')}</Typography>
                  <FormHelperText
                    sx={{ ml: '14px' }}
                    error={!!errors['image']}
                    id="component-error-text"
                  >
                    {errors['image']}
                  </FormHelperText>
                </Grid>
              </Grid>
              {
                thumb ?
                  <Grid container columnSpacing={1} alignItems={'center'}>
                    <Grid item xs={'auto'}>
                      <img
                        onClick={() => setOpenImage(true)}
                        style={thumbStyle}
                        src={thumb}
                        alt="image"
                      />
                    </Grid>
                  </Grid> :
                  null
              }
              {
                openImage && (
                  <ImageViewer
                    backgroundStyle={{ zIndex: 999999 }}
                    src={[original]}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={() => setOpenImage(false)}
                  />
                )
              }
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  {isSubmitting && <LinearProgress />}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Button
                    variant="outlined"
                    onClick={() => navigate(-1)}
                  >
                    {intl.get('common.cancel')}
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {intl.get('common.save')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
