import React, { useEffect, useMemo, useState } from 'react';

import { Autocomplete, Button, Grid, LinearProgress } from '@mui/material';
import MuiTextField from '@mui/material/TextField';

import intl from 'react-intl-universal';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { AxiosError } from 'axios';

import { useToastify } from 'hooks/toastfy';

import { useAppSelector } from 'store/hooks';

import User from 'models/User';
import Segment from 'models/Segment';

import { displayError } from 'helpers/http';

import api from 'shared/api';
import { initIntl } from 'shared/locales';
import { UTILS, ENDPOINTS, ROUTES } from 'shared/constants';

import { SelectOption } from 'components/Table/Filter';
import Breadcrumbs from 'components/Breadcrumbs';
import AutocompleteAsync, { AutocompleteAsyncOption } from 'components/AutocompleteAsync';

interface FormData {
  name: string;
  users_ids: SelectOption[];
}

initIntl();

const getValidation = (user: User | null) => yup.object({
  name: yup
    .string()
    .required(intl.get('validation.requiredField'))
    .max(UTILS.STRING_MAX_LEN),
  users_ids: yup
    .array()
    .min(1, intl.get('validation.requiredField'))
    .required(intl.get('validation.requiredField')),
});

/**
 * FormSegment component
 * @return {JSX.Element}
 */
export default function FormSegment(): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const toast = useToastify();
  const { id } = useParams<{ id: string }>();
  const [userOptions, setUserOptions] = useState<SelectOption[]>([]);
  const [segmentOptions, setSegmentOptions] = useState<AutocompleteAsyncOption[]>([]);
  const [initialValues, setInitialValues] = useState({
    name: UTILS.BLANK,
    users_ids: [],
  });

  const validationSchema = getValidation(user);

  useEffect(() => {
    if (id) {
      getSegment();
    }
    getCoordinators();
    getSegments();
  }, []);

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: intl.get('pages.segments.title'),
        onClickCallback: () => navigate(ROUTES.SEGMENTS),
      },
      {
        name: `${id ? intl.get('common.edit') : intl.get('common.add')}${UTILS.SPACE}${intl.get('pages.formSegment.title')}`,
        onClickCallback: () => { },
      },
    ];
  }, [id]);

  const getSegment = async () => {
    if (id) {
      const response = await api.get(ENDPOINTS.SEGMENTS.GET.BY_ID.replace(':id', id));
      setInitialValues({
        name: response.data.name,
        users_ids: response.data.users.map((user: User) => ({
          value: user.id,
          label: user.name,
        })),
      });
    }
  };

  const getSegments = async () => {
    const response = await api.get(ENDPOINTS.SEGMENTS.GET.ALL);
    const options = response.data.map((segment: Segment) => ({
      name: segment.name,
    }));

    setSegmentOptions(options);
  };

  const getCoordinators = async () => {
    const response = await api.get(ENDPOINTS.USERS.GET.COORDINATORS);
    const coordinators = response.data.map((user: User) => ({
      value: user.id,
      label: user.name,
    }));
    setUserOptions(coordinators);
  };

  const save = async (values: FormData) => {
    try {
      const data = {
        ...values,
        users_ids: values.users_ids.map((userId) => userId.value),
      };
      if (id) {
        await api.put(ENDPOINTS.SEGMENTS.PUT.BY_ID.replace(':id', id), data);
      } else {
        await api.post(ENDPOINTS.SEGMENTS.POST.ADD, data);
      }
      toast.success(intl.get('toast.saveSuccess'));
    } catch (error) {
      displayError(error as AxiosError);
    }
  };

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await save(values);
          setSubmitting(false);
          navigate(ROUTES.SEGMENTS);
        }}
      >
        {({ submitForm, isSubmitting, setFieldValue, values, errors }) => (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // submitForm();
              }
            }}
          >
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <AutocompleteAsync<Segment>
                    options={segmentOptions}
                    value={{ id: id ? parseInt(id) : undefined, name: values.name }}
                    index={0}
                    onChange={(name) => setFieldValue('name', name)}
                    label={intl.get('pages.formSegment.name')}
                    disabled={false}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    fullWidth
                    filterSelectedOptions
                    options={userOptions}
                    getOptionLabel={(option: SelectOption) => option.label}
                    value={values['users_ids']}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(e, value) => setFieldValue('users_ids', value)}
                    renderInput={(params) => (
                      <MuiTextField
                        error={!!errors['users_ids']}
                        helperText={!!errors['users_ids'] ? intl.get('validation.requiredField') : UTILS.BLANK}
                        {...params}
                        label={intl.get('pages.formSegment.coordinators')}
                        placeholder={intl.get('pages.formSegment.coordinatorsPlaceholder')}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  {isSubmitting && <LinearProgress />}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Button
                    variant="outlined"
                    onClick={() => navigate(-1)}
                  >
                    {intl.get('common.cancel')}
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {intl.get('common.save')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
