import React, { useEffect, useMemo, useState } from 'react';

import { Button, Grid, LinearProgress, MenuItem } from '@mui/material';

import intl from 'react-intl-universal';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { AxiosError } from 'axios';

import { useToastify } from 'hooks/toastfy';

import { useAppSelector } from 'store/hooks';

import School from 'models/School';
import User from 'models/User';

import { displayError } from 'helpers/http';

import api from 'shared/api';
import { initIntl } from 'shared/locales';
import { UTILS, ENDPOINTS, ROLES, ROUTES } from 'shared/constants';

import { SelectOption } from 'components/Table/Filter';
import ConditionalContainer from 'components/ConditionalContainer';
import Breadcrumbs from 'components/Breadcrumbs';

interface FormData {
  name: string;
  email: string;
  role_id: string;
}

initIntl();

const getValidation = (user: User | null) => yup.object({
  name: yup
    .string()
    .required(intl.get('validation.requiredField'))
    .max(UTILS.STRING_MAX_LEN),
  email: yup
    .string()
    .email(intl.get('validation.invalidFormat'))
    .required(intl.get('validation.requiredField')),
  role_id: yup
    .string()
    .min(1, intl.get('validation.requiredField'))
    .oneOf(Object.values(ROLES))
    .required(intl.get('validation.requiredField')),
  school_id: user && user.role.id.toString() === ROLES.ADMIN ?
    yup
      .string()
      .min(1, intl.get('validation.requiredField'))
      .when('role_id', {
        is: (roleId: string) => ![ROLES.ADMIN, ROLES.AEE].includes(roleId),
        then: yup.string().required(intl.get('validation.requiredField')),
      }) :
    yup
      .string()
      .optional(),
});

/**
 * FormUser component
 * @return {JSX.Element}
 */
export default function FormUser(): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const toast = useToastify();
  const { id } = useParams<{ id: string }>();
  const [schoolOptions, setSchoolOptions] = useState<SelectOption[]>([]);
  const [initialValues, setInitialValues] = useState({
    name: UTILS.BLANK,
    email: UTILS.BLANK,
    role_id: UTILS.BLANK,
    school_id: UTILS.BLANK,
  });

  const rolesOptions = useMemo(() => {
    if (user && user.role.id.toString() === ROLES.SCHOOL) {
      return [
        { value: ROLES.COORDINATOR, label: intl.get('common.roles.coordinator') },
      ];
    }
    if (user && user.role.id.toString() === ROLES.COORDINATOR) {
      return [
        { value: ROLES.TEACHER, label: intl.get('common.roles.teacher') },
      ];
    }
    if (user && user.role.id.toString() === ROLES.ADMIN) {
      return [
        { value: ROLES.ADMIN, label: intl.get('common.roles.admin') },
        { value: ROLES.SCHOOL, label: intl.get('common.roles.school') },
        { value: ROLES.COORDINATOR, label: intl.get('common.roles.coordinator') },
        { value: ROLES.TEACHER, label: intl.get('common.roles.teacher') },
        { value: ROLES.AEE, label: intl.get('common.roles.aee') },
      ];
    }
    return [];
  }, [user]);

  const validationSchema = getValidation(user);

  useEffect(() => {
    if (id) {
      getUser();
    }
  }, []);

  useEffect(() => {
    if (user && user.role.id.toString() === ROLES.ADMIN) {
      getSchools();
    }
  }, [user]);

  const breadcrumbs = useMemo(() => {
    if (user) {
      const names = user.role.id.toString() === ROLES.COORDINATOR ?
        [intl.get('pages.users.teachers'), intl.get('pages.formUser.teacher')] :
        [intl.get('pages.users.title'), intl.get('pages.formUser.title')];

      return [
        {
          name: names[0],
          onClickCallback: () => navigate(ROUTES.USERS),
        },
        {
          name: `${id ? intl.get('common.edit') : intl.get('common.add')}${UTILS.SPACE}${names[1]}`,
          onClickCallback: () => { },
        },
      ];
    }
    return [];
  }, [id, user]);

  const getUser = async () => {
    if (id) {
      const response = await api.get(ENDPOINTS.USERS.GET.BY_ID.replace(':id', id));
      setInitialValues({
        name: response.data.name,
        email: response.data.email,
        role_id: response.data.role.id,
        school_id: response.data.school ? response.data.school.id : UTILS.BLANK,
      });
    }
  };

  const getSchools = async () => {
    const response = await api.get(ENDPOINTS.SCHOOLS.GET.ALL);
    const schools = response.data.map((school: School) => ({
      value: school.id,
      label: school.name,
    }));
    setSchoolOptions(schools);
  };

  const save = async (values: FormData) => {
    try {
      if (id) {
        await api.put(ENDPOINTS.USERS.PUT.BY_ID.replace(':id', id), values);
      } else {
        await api.post(ENDPOINTS.USERS.POST.ADD, values);
      }
      toast.success(intl.get('toast.saveSuccess'));
    } catch (error) {
      displayError(error as AxiosError);
    }
  };

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await save(values);
          setSubmitting(false);
          navigate(ROUTES.USERS);
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                submitForm();
              }
            }}
          >
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    type="text"
                    label={intl.get('pages.formUser.name')}
                    name="name"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    name="email"
                    type="email"
                    label={intl.get('pages.formUser.email')}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} md={6}>
                  <Grid container justifyContent={'space-between'} rowSpacing={3}>
                    <Grid item xs={12} md={user && user.role.id.toString() !== ROLES.ADMIN ? 12 : 5.75}>
                      <Field
                        component={TextField}
                        select={true}
                        fullWidth
                        label={intl.get('pages.formUser.role')}
                        name="role_id"
                        inputProps={{
                          id: 'role-id',
                        }}
                      >
                        <MenuItem value="">
                          -
                        </MenuItem>
                        {
                          rolesOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))
                        }
                      </Field>
                    </Grid>
                    <ConditionalContainer
                      noComponent
                      checkIf={!!user && user.role.id.toString() === ROLES.ADMIN && ![ROLES.ADMIN, ROLES.AEE].includes(values.role_id)}
                    >
                      <Grid item xs={12} md={5.75}>
                        <Field
                          component={TextField}
                          select={true}
                          fullWidth
                          label={intl.get('pages.formUser.school')}
                          name="school_id"
                          inputProps={{
                            id: 'school-id',
                          }}
                        >
                          <MenuItem value="">
                            -
                          </MenuItem>
                          {
                            schoolOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))
                          }
                        </Field>
                      </Grid>
                    </ConditionalContainer>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  {isSubmitting && <LinearProgress />}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Button
                    variant="outlined"
                    onClick={() => navigate(-1)}
                  >
                    {intl.get('common.cancel')}
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {intl.get('common.save')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
