import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import viewSchoolReducer from './features/viewSchool/viewSchoolSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    viewSchool: viewSchoolReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
        ],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
