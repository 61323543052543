import { createSlice } from '@reduxjs/toolkit';

import { UTILS } from 'shared/constants';

export interface ViewSchoolState {
  viewSchoolId: string;
  viewSchoolName: string;
}

const initialState: ViewSchoolState = {
  viewSchoolId: UTILS.BLANK,
  viewSchoolName: UTILS.BLANK,
};

const viewSchoolSlice = createSlice({
  name: 'viewSchool',
  initialState,
  reducers: {
    setViewSchoolInfo(state, action) {
      state.viewSchoolId = action.payload.id;
      state.viewSchoolName = action.payload.name;
    },
    unsetViewSchoolInfo(state) {
      state.viewSchoolId = UTILS.BLANK;
      state.viewSchoolName = UTILS.BLANK;
    },
  },
});

export const { setViewSchoolInfo, unsetViewSchoolInfo } = viewSchoolSlice.actions;

export default viewSchoolSlice.reducer;
