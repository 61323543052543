import React, { useEffect, useState } from 'react';

import { Grid, LinearProgress, TextField, Typography } from '@mui/material';

import intl from 'react-intl-universal';

import { ROLES, UTILS } from 'shared/constants';

import { useAppSelector } from 'store/hooks';

import ConditionalContainer from 'components/ConditionalContainer';

interface Props {
  initialValue: string;
  userBelongsToSubject: boolean;
  onChange: (value: string) => void;
  onSave: () => void;
  isSubmiting: boolean;
}

/**
 * Evaluation component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function Evaluation(props: Props): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const [evaluation, setEvaluation] = useState(UTILS.BLANK);

  useEffect(() => {
    setEvaluation(props.initialValue);
  }, [props.initialValue]);

  useEffect(() => {
    if (props.initialValue !== evaluation) {
      const saveReportSubjectTimeOutId = setTimeout(() => props.onSave(), UTILS.AUTO_SAVE_TIMEOUT);
      return () => clearTimeout(saveReportSubjectTimeOutId);
    }
  }, [evaluation]);

  return <>
    <Grid item xs={12}>
      <Typography mb={2}>
        {intl.get('pages.viewStudent.map.evaluationText')}
      </Typography>
      <TextField
        fullWidth
        multiline
        minRows={3}
        variant="outlined"
        id={'evaluation'}
        label={intl.get('pages.viewStudent.map.evaluationLabel')}
        value={evaluation || UTILS.BLANK}
        error={evaluation.length === 0}
        disabled={(user && user.role.id.toString() === ROLES.TEACHER && !props.userBelongsToSubject) || props.isSubmiting}
        onChange={(e) => {
          setEvaluation(e.target.value);
          props.onChange(e.target.value);
        }}
      />
    </Grid>
    <ConditionalContainer noComponent checkIf={props.isSubmiting}>
      <Grid item xs={12} mb={3} mt={1}>
        <Grid item xs={12} md={6}>
          {props.isSubmiting && <LinearProgress />}
        </Grid>
      </Grid>
    </ConditionalContainer>
  </>;
}
