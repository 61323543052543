import React, { Fragment, SyntheticEvent, useEffect, useState } from 'react';

import MuiAutocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { CircularProgress, FilterOptionsState, TextField } from '@mui/material';

import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import { UTILS } from 'shared/constants';
import api from 'shared/api';

import { displayError } from 'helpers/http';

interface Props<T> {
  value: AutocompleteAsyncOption;
  index: number;
  disabled: boolean;
  onChange: (name: string, index: number) => void;
  label: string;
  options: AutocompleteAsyncOption[];
  async?: boolean;
  searchUrl?: string;
}

export interface AutocompleteAsyncOption {
  name: string;
  id?: number;
  inputValue?: string;
}

const filter = createFilterOptions<AutocompleteAsyncOption>();

/**
 * AutocompleteAsync component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function AutocompleteAsync<T extends {id: number, name: string}>(props: Props<T>): JSX.Element {
  const [options, setOptions] = useState<AutocompleteAsyncOption[]>([]);
  const [value, setValue] = useState<AutocompleteAsyncOption | null>(null);
  const [query, setQuery] = useState(UTILS.BLANK);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [open, setOpen] = useState(false);
  const id = `autocompleteAsync${props.index}`;

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    setValue({
      name: props.value.name,
      id: props.value.id,
    });
  }, [props.value]);

  useEffect(() => {
    if (!open && props.async) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (value && isDirty) {
      props.onChange(value.name, props.index);
      setIsDirty(false);
    } else if (props.async) {
      setOptions([]);
    }
  }, [value]);

  useEffect(() => {
    const minLength = 4;
    if (query.length >= minLength && props.async) {
      const queryGoals = setTimeout(() => getOptions(query), 500);
      return () => clearTimeout(queryGoals);
    }
  }, [query]);

  const getOptions = async (query?: string) => {
    try {
      setIsLoading(true);
      const response = await api.get((props.searchUrl ?? UTILS.BLANK).replace(':query', encodeURIComponent(query || UTILS.BLANK)));
      setIsLoading(false);

      const newOptions: AutocompleteAsyncOption[] = response.data.map((value: T) => ({
        id: value.id,
        name: value.name,
      }));

      setOptions(newOptions);
    } catch (error) {
      setIsLoading(false);
      displayError(error as AxiosError);
    }
  };

  const getOptionLabel = (option: string | AutocompleteAsyncOption) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.name;
  };

  const filterOptions = (options: AutocompleteAsyncOption[], params: FilterOptionsState<AutocompleteAsyncOption>) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.name);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue: inputValue,
        name: `${intl.get('common.add')} "${inputValue}"`,
      });
    }

    return filtered;
  };

  const onOptionChange = (event: SyntheticEvent<Element, Event>, newValue: string | AutocompleteAsyncOption | null) => {
    setIsDirty(true);
    if (typeof newValue === 'string') {
      setValue({
        name: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setValue({
        name: newValue.inputValue,
      });
    } else {
      setValue(newValue);
    }
  };

  return <MuiAutocomplete
    freeSolo
    fullWidth
    clearOnBlur
    selectOnFocus
    handleHomeEndKeys
    id={id}
    open={open}
    value={value}
    loading={isLoading}
    options={options}
    disabled={props.disabled}
    onOpen={() => setOpen(true)}
    onClose={() => setOpen(false)}
    onChange={onOptionChange}
    filterOptions={filterOptions}
    getOptionLabel={getOptionLabel}
    renderOption={(props, option) => <li {...props} key={`${option.id}${option.name}`}>{option.name}</li>}
    renderInput={(params) => (
      <TextField
        {...params}
        label={props.label}
        onChange={(e) => setQuery(e.target.value)}
        error={(!!value && value.name.length === 0) || !!!value}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <Fragment>
              {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              {/* {params.InputProps.endAdornment} */}
            </Fragment>
          ),
        }}
      />
    )}
  />;
}
