import React, { useEffect, useMemo, useState } from 'react';

import { FormControl, Grid, Hidden, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';

import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import { useToastify } from 'hooks/toastfy';

import { useAppSelector } from 'store/hooks';

import { ENDPOINTS, ROLES, UTILS } from 'shared/constants';
import api from 'shared/api';

import { displayError } from 'helpers/http';

import Team from 'models/Team';
import Report, { ReportSubject } from 'models/Report';

import ConditionalContainer from 'components/ConditionalContainer';
import { SelectOption } from 'components/Table/Filter';
import WeekDevelopmentFields, { WeekDevelopment } from './WeekDevelopmentFields';
import { minGoals } from '../Pide';
import WeeksDevelopmentChart from 'components/reportPDF/WeeksDevelopmentChart';

interface Props {
  team: Team
  report: Report
  subjectOptions: SelectOption[]
  loggedSubjectIds: string[]
  onRefresh: () => {}
  shouldRefresh: () => {}
}

/**
 * Dsde component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function Dsde(props: Props): JSX.Element {
  const toast = useToastify();
  const { user } = useAppSelector((state) => state.auth);
  const [weeksDevelopment, setWeeksDevelopment] = useState<WeekDevelopment[]>([]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [subjectId, setSubjectId] = useState(UTILS.BLANK);
  const [reportSubjectId, setReportSubjectId] = useState(UTILS.BLANK);
  const [isDirty, setIsDirty] = useState(false);
  const [fastSave, setFastSave] = useState(false);

  useEffect(() => {
    if (props.subjectOptions.length && !subjectId) {
      if (user && user.role.id.toString() !== ROLES.TEACHER) {
        setSubjectId(props.subjectOptions[0].value);
      } else {
        setSubjectId(props.loggedSubjectIds[0]);
      }
    }
  }, [props.subjectOptions]);

  useEffect(() => {
    if (subjectId) {
      const newWeeks = props.report.reportSubjects
        .filter((reportSubject: ReportSubject) => reportSubject.subjectId.toString() === subjectId)
        .flatMap((reportSubject: ReportSubject) => {
          setReportSubjectId(reportSubject.id.toString());
          return [
            { week: reportSubject.week1, score: reportSubject.week1Score },
            { week: reportSubject.week2, score: reportSubject.week2Score },
            { week: reportSubject.week3, score: reportSubject.week3Score },
            { week: reportSubject.week4, score: reportSubject.week4Score },
            { week: reportSubject.week5, score: reportSubject.week5Score },
            { week: reportSubject.week6, score: reportSubject.week6Score },
            { week: reportSubject.week7, score: reportSubject.week7Score },
            { week: reportSubject.week8, score: reportSubject.week8Score },
            { week: reportSubject.week9, score: reportSubject.week9Score },
            { week: reportSubject.week10, score: reportSubject.week10Score },
            { week: reportSubject.week11, score: reportSubject.week11Score },
            { week: reportSubject.week12, score: reportSubject.week12Score },
          ];
        });
      setWeeksDevelopment(newWeeks);
      setIsDirty(false);
    }
  }, [subjectId, props.report]);

  useEffect(() => {
    if (isDirty) {
      const saveGoalDescriptionTimeOutId = setTimeout(() => handleSaveReportSubject(),
        fastSave ? UTILS.AUTO_SAVE_FAST_TIMEOUT : UTILS.AUTO_SAVE_TIMEOUT,
      );
      return () => clearTimeout(saveGoalDescriptionTimeOutId);
    }
  }, [weeksDevelopment]);

  const userBelongsToSubject = useMemo(() => props.loggedSubjectIds.includes(subjectId), [props.loggedSubjectIds, subjectId]);

  const subjectsLabel = useMemo(() => props.team.special ?
    intl.get('pages.viewStudent.contentLabel') :
    intl.get('pages.viewStudent.subjectLabel'), [props]);

  const handleSubjectChange = (event: SelectChangeEvent) => {
    props.onRefresh();
    setSubjectId(event.target.value);
  };

  const handleChangeWeek = (name: string, value: string, index: number) => {
    const weeksCopy = [...weeksDevelopment];
    weeksCopy[index] = { ...weeksCopy[index], [name]: value };
    setWeeksDevelopment(weeksCopy);
    setIsDirty(true);
    if (name === 'score') {
      setFastSave(true);
    } else {
      setFastSave(false);
    }
  };

  const handleSaveReportSubject = async () => {
    try {
      setIsSubmiting(true);
      const weeksDevelopmentCopy = [...weeksDevelopment];

      const data = props.report.reportSubjects
        .filter((reportSubject: ReportSubject) => reportSubject.subjectId.toString() === subjectId)
        .flatMap((reportSubject: ReportSubject) => {
          const weekDevelopmentData: { [key: string]: string | number } = {};
          weeksDevelopmentCopy.forEach((weekCopy, index) => {
            weekDevelopmentData[`week_${index + 1}`] = weekCopy.week;
            weekDevelopmentData[`week_${index + 1}_score`] = weekCopy.score;
          });

          return {
            evaluation: reportSubject.evaluation,
            considerations: reportSubject.considerations,
            ...weekDevelopmentData,
          };
        })
        .shift();

      await api.put(ENDPOINTS.REPORT_SUBJECTS.PUT.BY_ID.replace(':id', reportSubjectId), data);

      props.shouldRefresh();
      setIsSubmiting(false);
      toast.success(intl.get('toast.saveSuccess'));
      // setWeeksDevelopment(weeksDevelopmentCopy);
    } catch (error) {
      setIsSubmiting(false);
      displayError(error as AxiosError);
    }
  };

  const renderWeeksDevelopmentGraph = () => <>
    <Grid item xs={12} display="flex" justifyContent="center">
      <Typography variant='h2' mb={2}>{intl.get('pages.viewStudent.dsde.weeksDevelopmentChart')}</Typography>
    </Grid>
    <Grid item xs={12} display="flex" justifyContent="center">
      <WeeksDevelopmentChart
        subjectId={parseInt(subjectId)}
        weeksDevelopment={weeksDevelopment}
      />
    </Grid>
  </>;

  return (<Grid container alignItems={'center'} columnGap={6} rowSpacing={3}>
    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <InputLabel id="subject-options-label">{subjectsLabel}</InputLabel>
        <Select
          labelId="subject-options-label"
          id="subject-options-select"
          value={subjectId}
          label={subjectsLabel}
          onChange={handleSubjectChange}
          sx={{ mb: 3 }}
        >
          {
            props.subjectOptions && props.subjectOptions.length ?
              props.subjectOptions.map((subjectOption, index) => <MenuItem key={index} value={subjectOption.value}>
                {subjectOption.label}
              </MenuItem>) :
              <MenuItem value={UTILS.BLANK}>
                {intl.get('pages.viewStudent.noOptions')}
              </MenuItem>
          }
        </Select>
      </FormControl>
    </Grid>
    <Grid container>
      <Hidden mdUp>
        <Grid item xs={12} md={6}>
          {!!weeksDevelopment.length && renderWeeksDevelopmentGraph()}
        </Grid>
      </Hidden>
      <Grid item xs={12} md={6}>
        {
          weeksDevelopment.map((weekDevelopment, index) => <Grid key={index} item xs={12}>
            <WeekDevelopmentFields
              value={weekDevelopment}
              index={index}
              isSubmiting={isSubmiting}
              userBelongsToSubject={userBelongsToSubject}
              onSave={handleSaveReportSubject}
              onChange={handleChangeWeek}
            />
          </Grid>)
        }
      </Grid>
      <Hidden mdDown>
        <Grid item xs={12} md={6}>
          {!!weeksDevelopment.length && renderWeeksDevelopmentGraph()}
        </Grid>
      </Hidden>
    </Grid>

    <ConditionalContainer noComponent checkIf={weeksDevelopment.length < minGoals}>
      <Grid item xs={12}>
        <Typography color={'primary'} variant="h2" fontWeight={'bold'}>
          {
            userBelongsToSubject || (user && [ROLES.SCHOOL, ROLES.COORDINATOR, ROLES.ADMIN, ROLES.AEE].includes(user.role.id.toString())) ?
              intl.get('pages.viewStudent.dsde.noGoalsCreateTip') :
              intl.get('pages.viewStudent.dsde.noGoalsInfoTip')
          }
        </Typography>
      </Grid>
    </ConditionalContainer>
    <ConditionalContainer noComponent checkIf={isSubmiting}>
      <Grid item xs={12} mb={3} mt={1}>
        <Grid item xs={12} md={6}>
          {isSubmiting && <LinearProgress />}
        </Grid>
      </Grid>
    </ConditionalContainer>
  </Grid>);
}
