import React, { useEffect, useMemo, useRef, useState } from 'react';

import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';

import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import { ReportSubjectBehavioralAspect } from 'models/ReportSubjectBehavioralAspect';
import Report, { ReportSubject, ReportSubjectImage } from 'models/Report';
import BehavioralAspect from 'models/BehavioralAspect';
import Team from 'models/Team';
import User from 'models/User';
import Goal from 'models/Goal';

import { ENDPOINTS, ROLES, UTILS } from 'shared/constants';
import api from 'shared/api';

import { displayError } from 'helpers/http';

import { useToastify } from 'hooks/toastfy';

import { useAppSelector } from 'store/hooks';

import { minGoals } from '../Pide';

import ConditionalContainer from 'components/ConditionalContainer';
import { SelectOption } from 'components/Table/Filter';
import BehavioralAspects from './BehavioralAspects';
import Evaluation from './Evaluation';
import Considerations from './Considerations';
import Goals from './Goals';
import VisualRecord from './VisualRecord';

interface Props {
  team: Team
  report: Report
  user: User
  subjectOptions: SelectOption[]
  behavioralAspects: BehavioralAspect[]
  loggedSubjectIds: string[]
  onRefresh: () => {}
  shouldRefresh: () => {}
}

/**
 * Mapa component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function Mapa(props: Props): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const toast = useToastify();
  const goalsInputs = useRef<Goal[]>([]);
  const evaluationInput = useRef(UTILS.BLANK);
  const considerationsInput = useRef(UTILS.BLANK);
  const [subjectId, setSubjectId] = useState(UTILS.BLANK);
  const [reportSubjectId, setReportSubjectId] = useState(UTILS.BLANK);
  const [reportSubjectImage, setReportSubjectImage] = useState<ReportSubjectImage>();
  const [isSubmitingReportSubject, setIsSubmitingReportSubject] = useState(false);
  const [reportSubjectBehavioralAspects, setReportSubjectBehavioralAspects] = useState<
    Map<number, ReportSubjectBehavioralAspect>
  >(new Map());

  useEffect(() => {
    if (props.subjectOptions.length && !subjectId) {
      if (user && user.role.id.toString() !== ROLES.TEACHER) {
        setSubjectId(props.subjectOptions[0].value);
      } else {
        setSubjectId(props.loggedSubjectIds[0]);
      }
    }
  }, [props.subjectOptions]);

  useEffect(() => {
    if (subjectId) {
      evaluationInput.current = UTILS.BLANK;
      considerationsInput.current = UTILS.BLANK;
      const newReportSubjectBehavioralAspects: Map<number, ReportSubjectBehavioralAspect> = new Map();

      const newGoals = props.report.reportSubjects
        .filter((reportSubject: ReportSubject) => {
          if (reportSubject.subjectId.toString() === subjectId) {
            setReportSubjectId(reportSubject.id.toString());
            setReportSubjectImage(reportSubject.image);
            evaluationInput.current = reportSubject.evaluation;
            considerationsInput.current = reportSubject.considerations;
            reportSubject.behavioralAspects.forEach((reportSubjectBehavioralAspect) => {
              newReportSubjectBehavioralAspects.set(reportSubjectBehavioralAspect.behavioralAspectId, reportSubjectBehavioralAspect);
            });
            return true;
          }
          return false;
        })
        .flatMap((reportSubject: ReportSubject) => reportSubject.goals);

      setReportSubjectBehavioralAspects(newReportSubjectBehavioralAspects);
      goalsInputs.current = newGoals;
    }
  }, [subjectId, props.report]);

  const userBelongsToSubject = useMemo(() => props.loggedSubjectIds.includes(subjectId), [props.loggedSubjectIds, subjectId]);

  const subjectsLabel = useMemo(() => props.team.special ?
    intl.get('pages.viewStudent.contentLabel') :
    intl.get('pages.viewStudent.subjectLabel'), [props]);

  const handleSubjectChange = (event: SelectChangeEvent) => {
    props.onRefresh();
    setSubjectId(event.target.value as string);
  };

  const handleSaveReportSubject = async () => {
    try {
      setIsSubmitingReportSubject(true);
      await api.put(ENDPOINTS.REPORT_SUBJECTS.PUT.BY_ID.replace(':id', reportSubjectId), {
        evaluation: evaluationInput.current,
        considerations: considerationsInput.current,
      });
      props.shouldRefresh();
      setIsSubmitingReportSubject(false);
      toast.success(intl.get('toast.saveSuccess'));
    } catch (error) {
      setIsSubmitingReportSubject(false);
      displayError(error as AxiosError);
    }
  };

  return <Grid container columnGap={6} rowSpacing={3}>
    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <InputLabel id="subject-options-label">{subjectsLabel}</InputLabel>
        <Select
          labelId="subject-options-label"
          id="subject-options-select"
          value={subjectId}
          label={subjectsLabel}
          onChange={handleSubjectChange}
        >
          {
            props.subjectOptions && props.subjectOptions.length ?
              props.subjectOptions.map((subjectOption, index) => <MenuItem key={index} value={subjectOption.value}>
                {subjectOption.label}
              </MenuItem>) :
              <MenuItem value={UTILS.BLANK}>
                {intl.get('pages.viewStudent.noOptions')}
              </MenuItem>
          }
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12} md={6}>
      <Grid container columnGap={6} rowSpacing={3}>
        {
          goalsInputs.current.length < minGoals ?
            <Grid item xs={12}>
              <Typography color={'primary'} variant="h2" fontWeight={'bold'}>
                {
                  userBelongsToSubject || (user && [ROLES.SCHOOL, ROLES.COORDINATOR].includes(user.role.id.toString())) ?
                    intl.get('pages.viewStudent.map.noGoalsCreateTip') :
                    intl.get('pages.viewStudent.map.noGoalsInfoTip')
                }
              </Typography>
            </Grid> :
            <>
              <Evaluation
                initialValue={evaluationInput.current}
                userBelongsToSubject={userBelongsToSubject}
                onChange={(val) => evaluationInput.current = val}
                onSave={handleSaveReportSubject}
                isSubmiting={isSubmitingReportSubject}
              />
              <Goals
                initialValue={goalsInputs.current}
                userBelongsToSubject={userBelongsToSubject}
                shouldRefresh={props.shouldRefresh}
              />
              <Considerations
                initialValue={considerationsInput.current}
                userBelongsToSubject={userBelongsToSubject}
                onChange={(val) => considerationsInput.current = val}
                onSave={handleSaveReportSubject}
                isSubmiting={isSubmitingReportSubject}
              />
              <BehavioralAspects
                shouldRefresh={props.shouldRefresh}
                behavioralAspects={props.behavioralAspects}
                reportSubjectBehavioralAspects={reportSubjectBehavioralAspects}
                reportSubjectId={reportSubjectId}
                userBelongsToSubject={userBelongsToSubject}
                setReportSubjectBehavioralAspects={setReportSubjectBehavioralAspects}
              />
            </>
        }
        <ConditionalContainer noComponent checkIf={goalsInputs.current.length >= minGoals}>
          <VisualRecord
            shouldRefresh={props.shouldRefresh}
            reportSubjectId={reportSubjectId}
            userBelongsToSubject={userBelongsToSubject}
            image={reportSubjectImage}
          />
        </ConditionalContainer>
      </Grid>
    </Grid>

  </Grid >;
}
