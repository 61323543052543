/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';

import intl from 'react-intl-universal';

import { ENDPOINTS, PERMISSIONS, ROUTES } from 'shared/constants';
import { initIntl } from 'shared/locales';
import api from 'shared/api';

import Student from 'models/Student';

import { useAppSelector } from 'store/hooks';

import Table, { Field } from 'components/Table';
import { Type } from 'components/NoResultsBox';

initIntl();

const getColumns = () => {
  return [
    intl.get('pages.students.id'),
    intl.get('pages.students.name'),
  ];
};

const getFields = (): Field[] => {
  return [
    {
      name: 'id',
    },
    {
      name: 'name',
    },
  ];
};

/**
 * Students component
 * @return {JSX.Element}
 */
function Students(): JSX.Element {
  const [students, setStudents] = useState<Student[]>([]);
  const { user } = useAppSelector((state) => state.auth);
  const { viewSchoolId } = useAppSelector((state) => state.viewSchool);

  useEffect(() => {
    getStudents();
  }, []);

  const fields = useMemo(() => {
    return getFields();
  }, []);

  const columns = useMemo(() => {
    return getColumns();
  }, []);

  const selects = useMemo(() => {
    return [];
  }, []);

  const getStudents = async () => {
    const response = await api.get(ENDPOINTS.STUDENTS.GET.ALL);
    setStudents(response.data);
  };

  return (
    <>
      {
        user ? <Table
          type={Type.student}
          columns={columns}
          fields={fields}
          onRefresh={getStudents}

          filter={{
            data: students,
            searchMatchFields: ['name'],
            searchPlaceholder: intl.get('pages.students.searchPlaceholder'),
            selects: selects,
          }}

          deleteDialog={{
            titleIntlKey: 'pages.students.deleteTitleDialog',
            descriptionIntlKey: 'pages.students.deleteDescriptionDialog',
          }}

          actions={{
            viewPath: PERMISSIONS.STUDENTS.VIEW.includes(user.role.id.toString()) || viewSchoolId ? ROUTES.STUDENTS_VIEW_REPORTS : undefined,
            addPath: PERMISSIONS.STUDENTS.CREATE.includes(user.role.id.toString()) || viewSchoolId ? ROUTES.STUDENTS_ADD : undefined,
            editPath: PERMISSIONS.STUDENTS.EDIT.includes(user.role.id.toString()) || viewSchoolId ? ROUTES.STUDENTS_EDIT : undefined,
            deleteEndpoint: PERMISSIONS.STUDENTS.REMOVE.includes(user.role.id.toString()) || viewSchoolId ? ENDPOINTS.STUDENTS.DELETE.BY_ID : undefined,
          }}
        /> : null
      }

    </>
  );
}

export default Students;
