import intl from 'react-intl-universal';

import { initIntl } from './locales';

import { SelectOption } from 'components/Table/Filter';

// PROD
export const API_HOST = 'https://sopassessoria.com.br/ii/api/api';
// HOMOLOG
// export const API_HOST = 'https://sopassessoria.com.br/ii_homolog/api/api';
// DEVELOPMENT
// export const API_HOST = 'http://127.0.0.1:8000/api';

export const APP_NAME = 'II';

export const UTILS = {
  AUTO_SAVE_TIMEOUT: 5000,
  AUTO_SAVE_FAST_TIMEOUT: 500,
  DEFAULT_DATE_FORMAT: 'yyyy-MM-dd',
  PASSWORD_MIN_LEN: 8,
  STRING_MAX_LEN: 255,
  TEXT_MAX_LEN: 500,
  BLANK: '',
  SPACE: ' ',
  ELLIPSIS: '...',
  DOT: '.',
  DASH: '-',
  SLASH: '/',
};

export const ERROR_STATUS = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

export const PAGINATION = {
  ROWS_PER_PAGE: [10, 20, 50],
  RADIX: 10,
};

export const ROLES = {
  ADMIN: '1',
  SCHOOL: '2',
  TEACHER: '3',
  COORDINATOR: '4',
  AEE: '5',
};

initIntl();

export const ROLES_OPTIONS: SelectOption[] = [
  { value: ROLES.ADMIN, label: intl.get('common.roles.admin') },
  { value: ROLES.SCHOOL, label: intl.get('common.roles.school') },
  { value: ROLES.TEACHER, label: intl.get('common.roles.teacher') },
  { value: ROLES.AEE, label: intl.get('common.roles.aee') },
];

export const RESET_PASSWORD = 'redefinir-a-senha';
export const CREATE_PASSWORD = 'nova-senha';

export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  NOT_FOUND: '/404',
  FORGOT_PASSWORD: '/esqueci-a-senha',
  RESET_PASSWORD: `/${RESET_PASSWORD}/:token`,
  CREATE_PASSWORD: `/${CREATE_PASSWORD}/:id/:token`,

  USERS: '/usuarios',
  USERS_ADD: '/usuarios/adicionar',
  USERS_EDIT: '/usuarios/editar/:id',

  SCHOOLS: '/escolas',
  SCHOOLS_ADD: '/escolas/adicionar',
  SCHOOLS_EDIT: '/escolas/editar/:id',
  SCHOOLS_VIEW: '/escolas/ver/:id',
  SCHOOLS_EDIT_IMAGE: '/escola/editar-imagem',

  HIGHLIGHTS: '/avisos',
  HIGHLIGHTS_ADD: '/avisos/adicionar',
  HIGHLIGHTS_EDIT: '/avisos/editar/:id',

  STUDENTS: '/estudantes',
  STUDENTS_ADD: '/estudantes/adicionar',
  STUDENTS_EDIT: '/estudantes/editar/:id',
  STUDENTS_VIEW_REPORTS: '/estudantes/relatorios/:id',

  SUBJECTS: '/disciplinas',
  SUBJECTS_ADD: '/disciplinas/adicionar',
  SUBJECTS_EDIT: '/disciplinas/editar/:id',

  SEGMENTS: '/segmentos',
  SEGMENTS_ADD: '/segmentos/adicionar',
  SEGMENTS_EDIT: '/segmentos/editar/:id',

  TEAMS: '/turmas',
  TEAMS_ADD: '/turmas/adicionar',
  TEAMS_EDIT: '/turmas/editar/:id',
  TEAMS_VIEW: '/turmas/ver/:id',
  TEAMS_VIEW_STUDENTS_VIEW: '/turmas/:teamId/estudantes/ver/:id/',

  SPECIAL_TEAMS: '/salas-multifuncionais',
  SPECIAL_TEAMS_ADD: '/salas-multifuncionais/adicionar',
  SPECIAL_TEAMS_EDIT: '/salas-multifuncionais/editar/:id',
  SPECIAL_TEAMS_VIEW: '/salas-multifuncionais/ver/:id',
  SPECIAL_TEAMS_STUDENTS_VIEW: '/salas-multifuncionais/:teamId/estudantes/ver/:id/',

  REPORTS_VIEW: '/relatorios/turma/:teamId/aluno/:studentId',
  REPORTS_PARTIAL_VIEW: '/relatorios/parcial/turma/:teamId/aluno/:studentId',
};

export const ENDPOINTS = {
  AUTH: {
    GET: {
      CSRF: '/csrf-cookie',
    },
    POST: {
      LOGIN: '/login',
      LOGOUT: '/logout',
    },
  },
  DASHBOARD: {
    GET: {
      ALL: '/dashboard',
    },
  },
  USERS: {
    GET: {
      AUTHENTICATED: '/users/authenticated',
      ALL: '/users',
      BY_ID: '/users/:id',
      TEACHERS: '/users/teachers',
      COORDINATORS: '/users/coordinators',
    },
    POST: {
      ADD: '/users',
      CREATE_PASSWORD: '/users/create-password/:id/:token',
      FORGOT_PASSWORD: '/users/forgot-password',
      RESET_PASSWORD: '/users/reset-password',
    },
    PUT: {
      BY_ID: '/users/:id',
    },
    DELETE: {
      BY_ID: '/users/:id',
    },
  },
  SCHOOLS: {
    GET: {
      ALL: '/schools',
      BY_ID: '/schools/:id',
      IMAGE_BY_ID: '/schools/image/:id',
    },
    POST: {
      ADD: '/schools',
    },
    PUT: {
      BY_ID: '/schools/:id',
    },
    DELETE: {
      BY_ID: '/schools/:id',
      IMAGE_BY_ID: '/schools/image/:id',
    },
  },
  HIGHLIGHTS: {
    GET: {
      ALL: '/highlights',
      BY_ID: '/highlights/:id',

    },
    POST: {
      ADD: '/highlights',
    },
    PUT: {
      BY_ID: '/highlights/:id',
    },
    DELETE: {
      BY_ID: '/highlights/:id',
    },
  },
  STUDENTS: {
    GET: {
      ALL: '/students',
      BY_ID: '/students/:id',
    },
    POST: {
      ADD: '/students',
    },
    PUT: {
      BY_ID: '/students/:id',
    },
    DELETE: {
      BY_ID: '/students/:id',
    },
  },
  SUBJECTS: {
    GET: {
      ALL: '/subjects',
      BY_ID: '/subjects/:id',

    },
    POST: {
      ADD: '/subjects',
    },
    PUT: {
      BY_ID: '/subjects/:id',
    },
    DELETE: {
      BY_ID: '/subjects/:id',
    },
  },
  SUBJECT_USERS: {
    GET: {
      ALL: '/subjects-users',
    },
  },
  SEGMENTS: {
    GET: {
      ALL: '/segments',
      BY_ID: '/segments/:id',

    },
    POST: {
      ADD: '/segments',
    },
    PUT: {
      BY_ID: '/segments/:id',
    },
    DELETE: {
      BY_ID: '/segments/:id',
    },
  },
  TEAMS: {
    GET: {
      TRADITIONAL: '/teams',
      SPECIAL: '/teams/special',
      BY_ID: '/teams/:id',
      BY_SCHOOL_ID: '/teams/by-school/:id',
    },
    POST: {
      ADD: '/teams',
    },
    PUT: {
      BY_ID: '/teams/:id',
    },
    DELETE: {
      BY_ID: '/teams/:id',
    },
  },
  STUDENT_TEAM: {
    GET: {
      BY_STUDENT_ID_AND_TEAM_ID: '/student-team/student/:studentId/team/:teamId',
    },
  },
  REPORTS: {
    POST: {
      ADD: '/reports',
    },
    PUT: {
      BY_ID: '/reports/:id',
    },
    DELETE: {
      BY_STUDENT_AND_TEAM: '/reports/student/:studentId/team/:teamId',
      BY_TEAM: '/reports/team/:teamId',
    },
    GET: {
      BY_STUDENT: '/reports/student/:studentId',
    },
  },
  GOALS: {
    POST: {
      ADD: '/goals',
    },
    PUT: {
      BY_ID: '/goals/:id',
    },
    DELETE: {
      BY_ID: '/goals/:id',
    },
    GET: {
      QUERY: '/goals?query=:query',
    },
  },
  REPORT_SUBJECTS: {
    PUT: {
      BY_ID: '/report-subjects/:id',
    },
  },
  BEHAVIORAL_ASPECTS: {
    GET: {
      ALL: '/behavioral-aspects',
    },
  },
  REPORT_SUBJECT_BEHAVIORAL_ASPECTS: {
    POST: {
      ONE: '/report-subject-behavioral-aspects',
    },
  },
  REPORT_SUBJECT_IMAGES: {
    POST: {
      ONE: '/report-subject-images',
    },
    GET: {
      BY_ID: '/report-subject-images/:id',
    },
    DELETE: {
      BY_ID: '/report-subject-images/:id',
    },
  },
};

export const PERMISSIONS = {
  HOME: {
    LIST: [ROLES.ADMIN, ROLES.SCHOOL, ROLES.COORDINATOR, ROLES.TEACHER, ROLES.AEE],
  },
  USERS: {
    CREATE: [ROLES.ADMIN, ROLES.SCHOOL, ROLES.COORDINATOR],
    EDIT: [ROLES.ADMIN, ROLES.SCHOOL, ROLES.COORDINATOR],
    REMOVE: [ROLES.ADMIN, ROLES.SCHOOL, ROLES.COORDINATOR],
    LIST: [ROLES.ADMIN, ROLES.SCHOOL, ROLES.COORDINATOR],
    VIEW: [ROLES.ADMIN, ROLES.SCHOOL, ROLES.COORDINATOR],
  },
  SCHOOLS: {
    CREATE: [ROLES.ADMIN],
    EDIT: [ROLES.ADMIN],
    REMOVE: [ROLES.ADMIN],
    LIST: [ROLES.ADMIN, ROLES.AEE],
    VIEW: [ROLES.ADMIN, ROLES.AEE],
    EDIT_IMAGE: [ROLES.SCHOOL],
  },
  HIGHLIGHTS: {
    CREATE: [ROLES.ADMIN],
    EDIT: [ROLES.ADMIN],
    REMOVE: [ROLES.ADMIN],
    LIST: [ROLES.ADMIN],
    VIEW: [ROLES.ADMIN],
  },
  STUDENTS: {
    CREATE: [ROLES.SCHOOL, ROLES.COORDINATOR],
    EDIT: [ROLES.SCHOOL, ROLES.COORDINATOR],
    REMOVE: [ROLES.SCHOOL, ROLES.COORDINATOR],
    LIST: [ROLES.SCHOOL, ROLES.COORDINATOR],
    VIEW: [ROLES.ADMIN, ROLES.SCHOOL, ROLES.COORDINATOR, ROLES.TEACHER, ROLES.AEE],
  },
  SUBJECTS: {
    CREATE: [ROLES.SCHOOL, ROLES.COORDINATOR],
    EDIT: [ROLES.SCHOOL, ROLES.COORDINATOR],
    REMOVE: [ROLES.SCHOOL, ROLES.COORDINATOR],
    LIST: [ROLES.SCHOOL, ROLES.COORDINATOR],
    VIEW: [ROLES.SCHOOL, ROLES.COORDINATOR],
  },
  SEGMENTS: {
    CREATE: [ROLES.SCHOOL],
    EDIT: [ROLES.SCHOOL],
    REMOVE: [ROLES.SCHOOL],
    LIST: [ROLES.SCHOOL],
    VIEW: [ROLES.SCHOOL],
  },
  TEAMS: {
    CREATE: [ROLES.SCHOOL, ROLES.COORDINATOR],
    EDIT: [ROLES.SCHOOL, ROLES.COORDINATOR],
    REMOVE: [ROLES.SCHOOL, ROLES.COORDINATOR],
    LIST: [ROLES.SCHOOL, ROLES.COORDINATOR, ROLES.TEACHER],
    VIEW: [ROLES.ADMIN, ROLES.SCHOOL, ROLES.COORDINATOR, ROLES.TEACHER],
  },
  REPORTS: {
    REMOVE: [ROLES.ADMIN, ROLES.SCHOOL, ROLES.COORDINATOR, ROLES.AEE],
  },
};
