import React, { ChangeEvent, useMemo, useState } from 'react';

import { Box, Button, TextField } from '@mui/material';

import intl from 'react-intl-universal';

import Report from 'models/Report';
import Team from 'models/Team';
import BehavioralAspect from 'models/BehavioralAspect';

import { ENDPOINTS, ROUTES } from 'shared/constants';
import api from 'shared/api';

import { useToastify } from 'hooks/toastfy';

import { parsePtBrToUs } from 'helpers/date';

import ReportPDF, { downloadReportButton as disabledReportButton } from 'components/reportPDF';
import ConditionalContainer from 'components/ConditionalContainer';

interface Props {
  team: Team
  report: Report
  behavioralAspects: BehavioralAspect[]
  lgUp: boolean
}

/**
 * Reports component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function Reports(props: Props): JSX.Element {
  const toast = useToastify();
  const [date, setDate] = useState(props.report.date);

  const showFullReport = useMemo(() => {
    // UNIQUE VALIDATION
    if (props.report.reportSubjects.length) {
      return true;
    }

    if (props.report.reportSubjects.length !== props.team.subjectsUsers.length) {
      return false;
    }

    for (let reportSubjectIndex = 0; reportSubjectIndex < props.report.reportSubjects.length; reportSubjectIndex++) {
      const reportSubject = props.report.reportSubjects[reportSubjectIndex];
      if (
        !reportSubject.goals.length ||
        !reportSubject.considerations ||
        !reportSubject.evaluation ||
        !reportSubject.week1 ||
        !reportSubject.week2 ||
        !reportSubject.week3 ||
        !reportSubject.week4 ||
        !reportSubject.week5 ||
        !reportSubject.week6 ||
        !reportSubject.week7 ||
        !reportSubject.week8 ||
        !reportSubject.week9 ||
        !reportSubject.week10 ||
        !reportSubject.week11 ||
        !reportSubject.week12 ||
        reportSubject.behavioralAspects.length < props.behavioralAspects.length
      ) {
        return false;
      }

      for (let goalsIndex = 0; goalsIndex < reportSubject.goals.length; goalsIndex++) {
        const goal = reportSubject.goals[goalsIndex];

        if (!goal.score) {
          return false;
        }
      }
    }

    return true;
  }, [props.report]);

  const saveDate = (event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);

    api.put(
      ENDPOINTS.REPORTS.PUT.BY_ID
        .replace(':id', props.report.id.toString()), { date: event.target.value },
    )
      .then(() => {
        toast.success(intl.get('toast.saveSuccess'));
      })
      .catch(() => {
        toast.success(intl.get('toast.saveError'));
      });
  };

  return (<>
    <TextField
      variant="outlined"
      type='date'
      sx={{ width: '300px', mb: 2 }}
      InputLabelProps={{ shrink: true }}
      label={intl.get('pages.viewStudent.report.dateLabel')}
      value={parsePtBrToUs(date)}
      onChange={saveDate}
    />
    {
      props.team && props.report ?
        props.lgUp ? <Box display={'flex'} flexDirection='column'>
          <Button
            disabled={!showFullReport}
            sx={{ width: '300px' }}
            onClick={() => window.open(ROUTES.REPORTS_VIEW.replace(':teamId', props.team.id.toString())
              .replace(':studentId', props.report.student.id.toString()), '_blank', 'noopener,noreferrer')
            }
            variant='contained'
            color='primary'
          >
            {intl.get('pages.viewStudent.report.full')}
          </Button>
          <Button
            sx={{ mt: 2, width: '300px' }}
            onClick={() => window.open(ROUTES.REPORTS_PARTIAL_VIEW.replace(':teamId', props.team.id.toString())
              .replace(':studentId', props.report.student.id.toString()), '_blank', 'noopener,noreferrer')
            }
            variant='contained'
            color='primary'
          >
            {intl.get('pages.viewStudent.report.partial')}
          </Button>
        </Box> :
          <>
            <ConditionalContainer checkIf={showFullReport} noComponent>
            </ConditionalContainer>
            {
              showFullReport ? <ReportPDF
                viewer={false}
                studentId={props.report.student.id.toString()}
                teamId={props.team.id.toString()}
              /> :
                disabledReportButton(false, true)
            }
            <ReportPDF
              partial
              viewer={false}
              studentId={props.report.student.id.toString()}
              teamId={props.team.id.toString()}
            />
          </> :
        null
    }
  </>);
}
