/* eslint-disable quote-props */
import React, { CSSProperties, Fragment, useMemo } from 'react';

import { Hidden, IconButton, Typography, Box, Toolbar, List, Divider, ListItem, SxProps, Theme, Button } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import intl from 'react-intl-universal';
import { NavLink, useNavigate } from 'react-router-dom';

import { unsetViewSchoolInfo } from 'store/features/viewSchool/viewSchoolSlice';
import { logout } from 'store/features/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useToastify } from 'hooks/toastfy';

import defaultTheme from 'shared/theme';
import { ROLES, ROUTES } from 'shared/constants';
import { getModelLocaleString } from 'shared/locales';

import { truncateString } from 'helpers/text';

import LogoIIClearImage from 'assets/image/logo_ii_clear.png';
import LogoSOPClearImage from 'assets/image/logo_sop_clear.png';

import items from './items';

const drawerWidth = 200;

const linkDefaultStyle: CSSProperties = {
  fontSize: 16,
  fontWeight: 400,
  textDecoration: 'none',
  color: '#FFFFFF',
};

const linkActiveStyle: CSSProperties = {
  fontSize: 16,
  fontWeight: 600,
  textDecoration: 'underline',
  color: '#FFFFFF',
};

const mobileImageStyle: CSSProperties = { cursor: 'pointer', width: '110px' };

const logoBoxStyle: SxProps<Theme> = {
  position: 'absolute',
  bottom: '20px',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
};

const imageStyle: CSSProperties = { cursor: 'pointer', height: '60px' };

const logoToolBarStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  height: '70px',
};

const menuIconStyle: SxProps<Theme> = { float: 'right' };

const drawerPaperStyle: SxProps<Theme> = {
  backgroundColor: defaultTheme.palette.primary.main,
  border: 'none',
};

const drawerStyle: SxProps<Theme> = {
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
};

const listItemStyle: SxProps<Theme> = { height: '50px' };

const unsetViewSchollInfoButtonStyle: SxProps<Theme> = {
  fontWeight: 600,
  color: '#FFFFFF',
  marginRight: 4,
};

interface Props {
  open: boolean
  onToggle: (open: boolean) => void
}

/**
 * Permanent Drawer component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function Drawer(props: Props): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const { viewSchoolId, viewSchoolName } = useAppSelector((state) => state.viewSchool);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToastify();

  const onLogout = async () => {
    await dispatch(logout());
  };

  const allowedItems = useMemo(() => {
    if (user) {
      if (viewSchoolId && user.role.id.toString() === ROLES.AEE) {
        const aeeAllowedItemIds = ['HOME', 'SCHOOLS', 'TEAMS', 'SPECIAL_TEAMS'];
        return items(user).filter((item) => aeeAllowedItemIds.includes(item.id));
      }
      if (viewSchoolId) {
        return items(user);
      }
      return items(user).filter((item) => item.allowed.includes(user.role.id.toString()));
    }
    return [];
  }, [user, viewSchoolId]);

  const getLinkStyle = (isActive: boolean) => {
    if (isActive) {
      return linkActiveStyle;
    }
    return linkDefaultStyle;
  };

  const getSopLogo = () => (<Box sx={logoBoxStyle}>
    <img style={mobileImageStyle} src={LogoSOPClearImage} onClick={() => navigate(ROUTES.ROOT)} />
  </Box>);

  const handleUnsetViewSchollInfo = () => {
    dispatch(unsetViewSchoolInfo());
    toast.success(intl.get('toast.unsetViewSchool'));
    navigate(ROUTES.SCHOOLS);
  };

  return (
    <Box display={'flex'}>
      <Hidden mdUp>
        <IconButton color="inherit" disableRipple onClick={() => props.onToggle(true)}>
          <MenuIcon sx={menuIconStyle} />
        </IconButton>
        <MuiDrawer
          anchor={'right'}
          open={props.open}
          onClose={() => props.onToggle(false)}
          PaperProps={{ sx: drawerPaperStyle }}
          sx={drawerStyle}
        >
          <Divider />
          <List disablePadding>
            <ListItem sx={{ height: '70px' }} onClick={onLogout}>
              {
                user ? <Typography sx={linkDefaultStyle}>{truncateString(user.name, 12)} ({user.role.name}) </Typography> : null
              }
              <IconButton sx={linkDefaultStyle} onClick={onLogout} aria-label={intl.get('components.appBar.logout')}>
                <LogoutIcon />
              </IconButton>
            </ListItem>
            {
              viewSchoolName &&
              <ListItem sx={{ height: '70px' }} onClick={handleUnsetViewSchollInfo}>
                <Typography sx={linkDefaultStyle}>
                  {intl.get('pages.schools.unsetViewSchoolInfo', { school: viewSchoolName })}
                </Typography>
                <IconButton sx={linkDefaultStyle}>
                  <ExitToAppIcon />
                </IconButton>
              </ListItem>
            }
            <Divider />
            {
              allowedItems.map((item, i) => <Fragment key={i}>
                <ListItem sx={listItemStyle} onClick={() => props.onToggle(false)}>
                  <NavLink
                    id={item.id}
                    to={item.path}
                    end={item.path === ROUTES.ROOT}
                    style={({ isActive }) => getLinkStyle(isActive)}
                  >
                    {getModelLocaleString(item, 'name')}
                  </NavLink>
                </ListItem>
                <Divider />
              </Fragment>)
            }
          </List>
          {getSopLogo()}
        </MuiDrawer>
      </Hidden>
      <Hidden mdDown>
        <MuiDrawer
          PaperProps={{ sx: drawerPaperStyle }}
          sx={drawerStyle}
          variant="permanent"
          anchor="left"
        >
          <Toolbar sx={logoToolBarStyle}>
            <img style={imageStyle} src={LogoIIClearImage} onClick={() => navigate(ROUTES.ROOT)} />
          </Toolbar>
          <div style={{ maxHeight: 'calc(100vh - 150px)', overflow: 'auto' }}>
            <Divider />
            <List>
              {
                allowedItems.map((item, i) => <Fragment key={i}>
                  <ListItem sx={listItemStyle}>
                    <NavLink
                      id={item.id}
                      to={item.path}
                      end={item.path === ROUTES.ROOT}
                      style={({ isActive }) => getLinkStyle(isActive)}
                    >
                      {getModelLocaleString(item, 'name')}
                    </NavLink>
                  </ListItem>
                  <Divider />
                </Fragment>)
              }
            </List>
          </div>
          {getSopLogo()}
        </MuiDrawer>
      </Hidden>
    </Box>
  );
}
