import React from 'react';

import { useMediaQuery } from '@mui/material';

import { useLocation, useParams } from 'react-router-dom';

import defaultTheme from 'shared/theme';

import ReportPDF from 'components/reportPDF';

/**
 * ViewReport component
 * @return {JSX.Element}
 */
export default function ViewReport(): JSX.Element {
  const location = useLocation();
  const lgUp = useMediaQuery(defaultTheme.breakpoints.up('lg'));
  const { studentId, teamId } = useParams<{ studentId: string, teamId: string }>();

  let partial = false;
  if (location.pathname.indexOf('parcial') > -1) {
    partial = true;
  }

  return studentId && teamId ? <ReportPDF partial={partial} viewer={lgUp} studentId={studentId} teamId={teamId} /> : <></>;
}
