import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Tab, Tabs, Tooltip, useMediaQuery } from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import Team from 'models/Team';
import Student from 'models/Student';
import Report from 'models/Report';
import BehavioralAspect from 'models/BehavioralAspect';

import { useAppSelector } from 'store/hooks';

import { ENDPOINTS, ROLES, ROUTES } from 'shared/constants';
import defaultTheme from 'shared/theme';
import api from 'shared/api';

import { displayError } from 'helpers/http';
import { sortAlphabetically } from 'helpers/array';

import ConditionalContainer from 'components/ConditionalContainer';
import { SelectOption } from 'components/Table/Filter';
import Breadcrumbs from 'components/Breadcrumbs';
import Pide from './Pide';
import Mapa from './Mapa';
import Dsde from './Dsde';
import Reports from './Reports';

/**
 * ViewStudent component
 * @return {JSX.Element}
 */
export default function ViewStudent(): JSX.Element {
  const navigate = useNavigate();
  const lgUp = useMediaQuery(defaultTheme.breakpoints.up('lg'));
  const { user } = useAppSelector((state) => state.auth);
  const { id, teamId } = useParams<{ id: string, teamId: string }>();
  const [team, setTeam] = useState<Team>();
  const [student, setStudent] = useState<Student>();
  const [report, setReport] = useState<Report>();
  const [tab, setTab] = useState(0);
  const [subjectOptions, setSubjectOptions] = useState<SelectOption[]>([]);
  const [behavioralAspects, setBehavioralAspects] = useState<BehavioralAspect[]>([]);
  const shouldRefresh = useRef(true);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  useEffect(() => {
    getBehavioralAspects();
  }, []);

  useEffect(() => {
    if (teamId) {
      getTeam();
    }
  }, [teamId]);

  useEffect(() => {
    if (id && team) {
      getStudent();
    }
  }, [id, team]);

  useEffect(() => {
    if (student && team && shouldRefresh.current) {
      getReport();
      shouldRefresh.current = false;
    }
  }, [student, team, tab]);

  useEffect(() => {
    if (team) {
      const newSubjectOptions = team.subjectsUsers
        .map((subjectsUser) => ({
          value: subjectsUser.subject.id.toString(),
          label: subjectsUser.subject.name,
        }));

      newSubjectOptions.sort((a, b) => sortAlphabetically<SelectOption>(a, b, 'label'));

      setSubjectOptions(newSubjectOptions);
    }
  }, [team]);

  const loggedSubjectIds = useMemo(() => {
    if (team && user) {
      return team.subjectsUsers
        .filter((subjectsUser) => subjectsUser.user.id === user.id)
        .map((subjectsUser) => subjectsUser.subject.id.toString());
    }
    return [];
  }, [team, user]);

  const breadcrumbs = useMemo(() => {
    if (team && student) {
      return [
        {
          name: intl.get('pages.teams.title'),
          onClickCallback: () => user?.role.id.toString() === ROLES.ADMIN ?
            navigate(ROUTES.SCHOOLS_VIEW.replace(':id', student.schoolId.toString())) :
            navigate(ROUTES.TEAMS),
        },
        {
          name: team.name,
          onClickCallback: () => navigate(ROUTES.TEAMS_VIEW.replace(':id', team.id.toString())),
        },
        {
          name: student.name,
          onClickCallback: () => { },
        },
      ];
    }
    return [];
  }, [team, student]);

  const getTeam = async () => {
    if (teamId) {
      try {
        const response = await api.get(ENDPOINTS.TEAMS.GET.BY_ID.replace(':id', teamId));
        setTeam(response.data);
      } catch (error) {
        displayError(error as AxiosError);
        navigate(ROUTES.TEAMS);
      }
    }
  };

  const getStudent = async () => {
    if (id && team) {
      try {
        const response = await api.get(ENDPOINTS.STUDENTS.GET.BY_ID.replace(':id', id));
        setStudent(response.data);
      } catch (error) {
        displayError(error as AxiosError);
        navigate(ROUTES.TEAMS_VIEW.replace(':id', team.id.toString()));
      }
    }
  };

  const getReport = async () => {
    if (student && team) {
      try {
        const studentTeamResponse = await api.get(
          ENDPOINTS.STUDENT_TEAM.GET.BY_STUDENT_ID_AND_TEAM_ID
            .replace(':studentId', student.id.toString())
            .replace(':teamId', team.id.toString()),
        );

        const response = await api.post(ENDPOINTS.REPORTS.POST.ADD, {
          student_team_id: studentTeamResponse.data.id,
          school_id: user && [ROLES.ADMIN, ROLES.AEE].includes(user?.role.id.toString()) ? student.schoolId : undefined,
        });

        setReport(response.data);
      } catch (error) {
        displayError(error as AxiosError);
      }
    }
  };

  const getBehavioralAspects = async () => {
    try {
      const response = await api.get(ENDPOINTS.BEHAVIORAL_ASPECTS.GET.ALL);
      setBehavioralAspects(response.data);
    } catch (error) {
      displayError(error as AxiosError);
    }
  };

  return <>
    <Breadcrumbs items={breadcrumbs} />
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        variant="scrollable"
        value={tab}
        onChange={handleChange}
        aria-label={intl.get('pages.viewStudent.tabsTitle')}
      >
        <Tab
          label={<Tooltip title={intl.get('pages.viewStudent.pideMeaning')}>
            <span>{intl.get('pages.viewStudent.pideTab')}</span>
          </Tooltip>}
        />
        <Tab
          label={<Tooltip title={intl.get('pages.viewStudent.dsdeMeaning')}>
            <span>{intl.get('pages.viewStudent.dsdeTab')}</span>
          </Tooltip>}
        />
        <Tab
          label={<Tooltip title={intl.get('pages.viewStudent.mapMeaning')}>
            <span>{intl.get('pages.viewStudent.mapTab')}</span>
          </Tooltip>}
        />
        <Tab label={intl.get('pages.viewStudent.reportTab')} />
      </Tabs>
    </Box>
    <Box pt={3}>
      <ConditionalContainer checkIf={tab === 0} noComponent>
        {
          team && report && user ?
            <Pide
              shouldRefresh={() => shouldRefresh.current = true}
              onRefresh={getReport}
              subjectOptions={subjectOptions}
              loggedSubjectIds={loggedSubjectIds}
              report={report}
              team={team}
            /> : null
        }
      </ConditionalContainer>
      <ConditionalContainer checkIf={tab === 1} noComponent>
        {
          team && report ?
            <Dsde
              shouldRefresh={() => shouldRefresh.current = true}
              onRefresh={getReport}
              subjectOptions={subjectOptions}
              loggedSubjectIds={loggedSubjectIds}
              report={report}
              team={team}
            /> : null
        }
      </ConditionalContainer>
      <ConditionalContainer checkIf={tab === 2} noComponent>
        {
          team && report && user ?
            <Mapa
              behavioralAspects={behavioralAspects}
              shouldRefresh={() => shouldRefresh.current = true}
              onRefresh={getReport}
              subjectOptions={subjectOptions}
              loggedSubjectIds={loggedSubjectIds}
              report={report}
              team={team}
              user={user}
            /> : null
        }
      </ConditionalContainer>
      <ConditionalContainer checkIf={tab === 3} noComponent>
        {
          team && report ?
            <Reports
              lgUp={lgUp}
              team={team}
              report={report}
              behavioralAspects={behavioralAspects}
            /> :
            null
        }
      </ConditionalContainer>
    </Box>
  </>;
}
