import React, { useEffect, useState } from 'react';

import { FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, Typography } from '@mui/material';

import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import api from 'shared/api';
import { ENDPOINTS, ROLES, UTILS } from 'shared/constants';

import Goal from 'models/Goal';

import { displayError } from 'helpers/http';

import { useToastify } from 'hooks/toastfy';

import { useAppSelector } from 'store/hooks';

import { SelectOption } from 'components/Table/Filter';
import ConditionalContainer from 'components/ConditionalContainer';

const evaluationOptions: SelectOption[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
];

interface Props {
  initialValue: Goal[]
  userBelongsToSubject: boolean
  shouldRefresh: () => {}
}

/**
 * Goals component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function Goals(props: Props): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const toast = useToastify();
  const [goals, setGoals] = useState<Goal[]>([]);
  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    setGoals(props.initialValue);
  }, [props.initialValue]);

  const handleUpdateGoalScore = async (goalId: number, score: string) => {
    try {
      const goalsCopy = [...goals];
      const index = goalsCopy.findIndex((goalCopy) => goalCopy.id === goalId);
      goalsCopy[index] = {
        ...goalsCopy[index],
        score: parseInt(score),
      };

      setIsSubmiting(true);
      await api.put(ENDPOINTS.GOALS.PUT.BY_ID.replace(':id', goalId.toString()), {
        ...goalsCopy[index],
        score,
      });
      setIsSubmiting(false);
      props.shouldRefresh();

      toast.success(intl.get('toast.saveSuccess'));
      setGoals(goalsCopy);
    } catch (error) {
      setIsSubmiting(false);
      displayError(error as AxiosError);
    }
  };

  return (<>
    {
      goals.map((goal, index) => <Grid key={index} item xs={12}>
        <Typography mb={2}>
          {goal.name}
        </Typography>
        <FormControl fullWidth error={!!!goal.score}>
          <InputLabel id="subject-options-label">{intl.get('pages.viewStudent.map.evaluationLabel')}</InputLabel>
          <Select
            labelId="subject-options-label"
            id="subject-options-select"
            value={goal.score?.toString() || UTILS.BLANK}
            label={intl.get('pages.viewStudent.map.evaluationLabel')}
            onChange={(e) => handleUpdateGoalScore(goal.id, e.target.value)}
            disabled={(user && user.role.id.toString() === ROLES.TEACHER && !props.userBelongsToSubject) || isSubmiting}
          >
            {
              evaluationOptions.map((subjectOption, index) => <MenuItem key={index} value={subjectOption.value}>
                {subjectOption.label}
              </MenuItem>)
            }
          </Select>
        </FormControl>
      </Grid>)
    }
    <ConditionalContainer noComponent checkIf={isSubmiting}>
      <Grid item xs={12} mb={3} mt={1}>
        <Grid item xs={12} md={6}>
          {isSubmiting && <LinearProgress />}
        </Grid>
      </Grid>
    </ConditionalContainer>
  </>);
}
