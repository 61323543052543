import React, { useEffect, useMemo, useState } from 'react';

import intl from 'react-intl-universal';

import { ENDPOINTS, PERMISSIONS, ROUTES } from 'shared/constants';
import { initIntl } from 'shared/locales';
import api from 'shared/api';

import User from 'models/User';
import Segment from 'models/Segment';

import { useAppSelector } from 'store/hooks';

import Table, { Field } from 'components/Table';
import { Type } from 'components/NoResultsBox';

initIntl();

const getColumns = (user: User) => {
  return [
    intl.get('pages.segments.id'),
    intl.get('pages.segments.name'),
  ];
};

const getFields = (user: User): Field[] => {
  return [
    {
      name: 'id',
    },
    {
      name: 'name',
    },
  ];
};

/**
 * Segments component
 * @return {JSX.Element}
 */
function Segments(): JSX.Element {
  const [segments, setSegments] = useState<Segment[]>([]);
  const { user } = useAppSelector((state) => state.auth);
  const { viewSchoolId } = useAppSelector((state) => state.viewSchool);

  useEffect(() => {
    getSegments();
  }, []);

  const fields = useMemo(() => {
    if (user) {
      return getFields(user);
    }
    return [];
  }, [user]);

  const columns = useMemo(() => {
    if (user) {
      return getColumns(user);
    }
    return [];
  }, [user]);

  const selects = useMemo(() => {
    return [];
  }, []);

  const getSegments = async () => {
    const response = await api.get(ENDPOINTS.SEGMENTS.GET.ALL);
    setSegments(response.data);
  };

  return (
    <>
      {
        user ? <Table
          type={Type.segment}
          columns={columns}
          fields={fields}
          onRefresh={getSegments}

          filter={{
            data: segments,
            searchMatchFields: ['name'],
            searchPlaceholder: intl.get('pages.segments.searchPlaceholder'),
            selects: selects,
          }}

          deleteDialog={{
            titleIntlKey: 'pages.segments.deleteTitleDialog',
            descriptionIntlKey: 'pages.segments.deleteDescriptionDialog',
          }}

          actions={{
            addPath: PERMISSIONS.SEGMENTS.CREATE.includes(user.role.id.toString()) || viewSchoolId ? ROUTES.SEGMENTS_ADD : undefined,
            editPath: PERMISSIONS.SEGMENTS.EDIT.includes(user.role.id.toString()) || viewSchoolId ? ROUTES.SEGMENTS_EDIT : undefined,
            deleteEndpoint: PERMISSIONS.SEGMENTS.REMOVE.includes(user.role.id.toString()) || viewSchoolId ?
              ENDPOINTS.SEGMENTS.DELETE.BY_ID : undefined,
          }}
        /> : null
      }

    </>
  );
}

export default Segments;
