import React, { useEffect, useMemo, useState } from 'react';

import { Box, Grid, Paper, SxProps, Typography, useMediaQuery, useTheme, Theme } from '@mui/material';

import { AxiosError } from 'axios';
import intl from 'react-intl-universal';
import CountUp from 'react-countup';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import './splide.css';

import api from 'shared/api';
import { ENDPOINTS } from 'shared/constants';

import { displayError } from 'helpers/http';

import { useAppSelector } from 'store/hooks';

import Highlight from 'models/Highlight';

const imageBoxStyle = (smDown: boolean): SxProps<Theme> => ({
  width: '100%',
  height: 500,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'flex',
});

/**
 * Home component
 * @return {JSX.Element}
 */
function Home(): JSX.Element {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [highlights, setHighlights] = useState<Highlight[]>([]);
  const { user } = useAppSelector((state) => state.auth);
  const [data, setData] = useState<{ [key: string]: number } | undefined>();

  useEffect(() => {
    getData();
    getHighlights();
  }, []);

  const totals = useMemo(() => {
    if (data) {
      return Object.values(data);
    }
    return [];
  }, [data]);

  const labels = useMemo(() => {
    if (data) {
      return Object.keys(data);
    }
    return [];
  }, [data]);

  const getData = async () => {
    try {
      const response = await api.get(ENDPOINTS.DASHBOARD.GET.ALL);
      setData(response.data);
    } catch (error) {
      displayError(error as AxiosError);
    }
  };

  const getHighlights = async () => {
    const response = await api.get(ENDPOINTS.HIGHLIGHTS.GET.ALL);
    setHighlights(response.data);
  };

  return <Grid container>
    {
      user ? <>
        <Grid item xs={12}>
          <Typography fontWeight="bold" variant="h1">{intl.get('pages.home.hello', { name: user?.name })}</Typography>
        </Grid>
        <Grid item xs={12} mb={3}>
          <Typography variant="h2">{intl.get('pages.home.welcome')}</Typography>
        </Grid>
      </> :
        null
    }
    <Grid item xs={12} md={6}>
      <Grid container spacing={2}>
        {
          totals.map((total, index) => <Grid item xs key={index}>
            <Paper elevation={3} sx={{ width: 'fit-content', minWidth: '130px' }}>
              <Box
                p={3}
                display="flex"
                justifyContent="space-around"
                flexDirection="column"
              >
                <Box>
                  <Typography color="primary" fontWeight="bold" align="center">{intl.get(`pages.dashboard.${labels[index]}`)}</Typography>
                </Box>
                <Box>
                  <Typography fontWeight="bold" align="center">
                    <CountUp end={total} />
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>)
        }
      </Grid>
    </Grid>
    {
      highlights.length ? <Grid container>
        <Grid item xs={12} md={6} marginTop={3}>
          <Splide
            id="highlights"
            aria-label="Destaques"
            options={{ rewind: true, pagination: true, autoplay: true, arrows: false }}
            hasTrack={false}
          >
            <SplideTrack>
              {
                highlights.map((highlight, index) => <SplideSlide key={index}>
                  <Box sx={{ ...imageBoxStyle(smDown), backgroundImage: `url(${highlight.path})` }} />
                </SplideSlide>)
              }
            </SplideTrack>
          </Splide>
        </Grid>
      </Grid> : null
    }
  </Grid>;
}

export default Home;
