import React, { useEffect, useMemo, useState } from 'react';

import intl from 'react-intl-universal';

import { ENDPOINTS, PERMISSIONS, ROUTES } from 'shared/constants';
import { initIntl } from 'shared/locales';
import api from 'shared/api';

import User from 'models/User';
import Subject from 'models/Subject';

import { useAppSelector } from 'store/hooks';

import Table, { Field } from 'components/Table';
import { Type } from 'components/NoResultsBox';

initIntl();

const getColumns = (user: User) => {
  return [
    intl.get('pages.subjects.id'),
    intl.get('pages.subjects.name'),
  ];
};

const getFields = (user: User): Field[] => {
  return [
    {
      name: 'id',
    },
    {
      name: 'name',
    },
  ];
};

/**
 * Subjects component
 * @return {JSX.Element}
 */
function Subjects(): JSX.Element {
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const { user } = useAppSelector((state) => state.auth);
  const { viewSchoolId } = useAppSelector((state) => state.viewSchool);

  useEffect(() => {
    getSubjects();
  }, []);

  const fields = useMemo(() => {
    if (user) {
      return getFields(user);
    }
    return [];
  }, [user]);

  const columns = useMemo(() => {
    if (user) {
      return getColumns(user);
    }
    return [];
  }, [user]);

  const selects = useMemo(() => {
    return [];
  }, []);

  const getSubjects = async () => {
    const response = await api.get(ENDPOINTS.SUBJECTS.GET.ALL);
    setSubjects(response.data);
  };

  return (
    <>
      {
        user ? <Table
          type={Type.subject}
          columns={columns}
          fields={fields}
          onRefresh={getSubjects}

          filter={{
            data: subjects,
            searchMatchFields: ['name'],
            searchPlaceholder: intl.get('pages.subjects.searchPlaceholder'),
            selects: selects,
          }}

          deleteDialog={{
            titleIntlKey: 'pages.subjects.deleteTitleDialog',
            descriptionIntlKey: 'pages.subjects.deleteDescriptionDialog',
          }}

          actions={{
            addPath: PERMISSIONS.SUBJECTS.CREATE.includes(user.role.id.toString()) || viewSchoolId ? ROUTES.SUBJECTS_ADD : undefined,
            editPath: PERMISSIONS.SUBJECTS.EDIT.includes(user.role.id.toString()) || viewSchoolId ? ROUTES.SUBJECTS_EDIT : undefined,
            deleteEndpoint: PERMISSIONS.SUBJECTS.REMOVE.includes(user.role.id.toString()) || viewSchoolId ?
              ENDPOINTS.SUBJECTS.DELETE.BY_ID : undefined,
          }}
        /> : null
      }

    </>
  );
}

export default Subjects;
