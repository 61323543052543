import React, { useEffect, useMemo, useState } from 'react';

import { Autocomplete, Button, Grid, LinearProgress, MenuItem } from '@mui/material';
import MuiTextField from '@mui/material/TextField';

import intl from 'react-intl-universal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { AxiosError } from 'axios';

import { useToastify } from 'hooks/toastfy';

import { useAppSelector } from 'store/hooks';

import User from 'models/User';
import Student from 'models/Student';
import SubjectUser from 'models/SubjectUser';
import Segment from 'models/Segment';

import { displayError } from 'helpers/http';

import api from 'shared/api';
import { initIntl } from 'shared/locales';
import { UTILS, ENDPOINTS, ROUTES } from 'shared/constants';

import { SelectOption } from 'components/Table/Filter';
import Breadcrumbs from 'components/Breadcrumbs';

interface FormData {
  name: string;
  segment_id: string;
  students_ids: SelectOption[];
  subjects_users_ids: SelectOption[];
}

initIntl();

const getValidation = (user: User | null) => yup.object({
  name: yup
    .string()
    .required(intl.get('validation.requiredField'))
    .max(UTILS.STRING_MAX_LEN),
  segment_id: yup
    .string()
    .min(1, intl.get('validation.requiredField'))
    .required(intl.get('validation.requiredField')),
  students_ids: yup
    .array()
    .min(1, intl.get('validation.requiredField'))
    .required(intl.get('validation.requiredField')),
  subjects_users_ids: yup
    .array()
    .min(1, intl.get('validation.requiredField'))
    .required(intl.get('validation.requiredField')),
});

/**
 * FormTeam component
 * @return {JSX.Element}
 */
export default function FormTeam(): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToastify();
  const { id } = useParams<{ id: string }>();
  const [studentOptions, setStudentOptions] = useState<SelectOption[]>([]);
  const [subjectUserOptions, setSubjectUserOptions] = useState<SelectOption[]>([]);
  const [segmentOptions, setSegmentOptions] = useState<SelectOption[]>([]);
  const [initialValues, setInitialValues] = useState({
    name: UTILS.BLANK,
    segment_id: UTILS.BLANK,
    students_ids: [],
    subjects_users_ids: [],
  });

  const validationSchema = getValidation(user);

  useEffect(() => {
    if (id) {
      getTeam();
    }
    getSegments();
    getStudents();
    getSubjectsUsers();
  }, []);

  const teamContext = useMemo(() => {
    if (location.pathname.search('multifuncionais') !== -1) {
      return {
        subjectsLabel: intl.get('pages.formTeam.contents'),
        subjectsPlaceholder: intl.get('pages.formTeam.contentsPlaceholder'),
        path: ROUTES.SPECIAL_TEAMS,
        special: 1,
      };
    }
    return {
      subjectsLabel: intl.get('pages.formTeam.subjects'),
      subjectsPlaceholder: intl.get('pages.formTeam.subjectsPlaceholder'),
      path: ROUTES.TEAMS,
      special: 0,
    };
  }, [location.pathname]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: intl.get('pages.teams.title'),
        onClickCallback: () => navigate(teamContext.path),
      },
      {
        name: `${id ? intl.get('common.edit') : intl.get('common.add')}${UTILS.SPACE}${intl.get('pages.formTeam.title')}`,
        onClickCallback: () => { },
      },
    ];
  }, [id]);

  const getTeam = async () => {
    if (id) {
      const response = await api.get(ENDPOINTS.TEAMS.GET.BY_ID.replace(':id', id));
      setInitialValues({
        name: response.data.name,
        segment_id: response.data.segment ? response.data.segment.id : UTILS.BLANK,
        students_ids: response.data.students.map((student: Student) => ({
          value: student.id,
          label: student.name,
        })),
        subjects_users_ids: response.data.subjectsUsers.map((subjectsUser: SubjectUser) => ({
          value: subjectsUser.id,
          label: `${subjectsUser.subject.name} - ${subjectsUser.user.name}`,
        })),
      });
    }
  };

  const getStudents = async () => {
    const response = await api.get(ENDPOINTS.STUDENTS.GET.ALL);
    const students = response.data.map((student: Student) => ({
      value: student.id,
      label: student.name,
    }));
    setStudentOptions(students);
  };

  const getSubjectsUsers = async () => {
    const response = await api.get(ENDPOINTS.SUBJECT_USERS.GET.ALL);
    const subjectsUsers = response.data.map((subjectsUser: SubjectUser) => ({
      value: subjectsUser.id,
      label: `${subjectsUser.subject.name} - ${subjectsUser.user.name}`,
    }));
    setSubjectUserOptions(subjectsUsers);
  };

  const getSegments = async () => {
    const response = await api.get(ENDPOINTS.SEGMENTS.GET.ALL);
    const segments = response.data.map((segment: Segment) => ({
      value: segment.id,
      label: segment.name,
    }));
    setSegmentOptions(segments);
  };

  const save = async (values: FormData) => {
    try {
      const data = {
        name: values.name,
        segment_id: values.segment_id,
        special: teamContext.special,
        students_ids: values.students_ids.map((studentId) => studentId.value),
        subjects_users_ids: values.subjects_users_ids.map((subjectUserId) => subjectUserId.value),
      };
      if (id) {
        await api.put(ENDPOINTS.TEAMS.PUT.BY_ID.replace(':id', id), data);
      } else {
        await api.post(ENDPOINTS.TEAMS.POST.ADD, data);
      }
      toast.success(intl.get('toast.saveSuccess'));
    } catch (error) {
      displayError(error as AxiosError);
    }
  };

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await save(values);
          setSubmitting(false);
          navigate(teamContext.path);
        }}
      >
        {({ submitForm, isSubmitting, setFieldValue, values, errors }) => (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                submitForm();
              }
            }}
          >
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    type="text"
                    label={intl.get('pages.formTeam.name')}
                    name="name"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <Field
                    component={TextField}
                    select={true}
                    fullWidth
                    label={intl.get('pages.formTeam.segment')}
                    name="segment_id"
                    inputProps={{
                      id: 'segment-id',
                    }}
                  >
                    <MenuItem value="">
                            -
                    </MenuItem>
                    {
                      segmentOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))
                    }
                  </Field>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    fullWidth
                    filterSelectedOptions
                    options={subjectUserOptions}
                    getOptionLabel={(option: SelectOption) => option.label}
                    value={values['subjects_users_ids']}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(e, value) => setFieldValue('subjects_users_ids', value)}
                    renderInput={(params) => (
                      <MuiTextField
                        error={!!errors['subjects_users_ids']}
                        helperText={!!errors['subjects_users_ids'] ? intl.get('validation.requiredField') : UTILS.BLANK}
                        {...params}
                        label={teamContext.subjectsLabel}
                        placeholder={teamContext.subjectsPlaceholder}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    fullWidth
                    filterSelectedOptions
                    options={studentOptions}
                    getOptionLabel={(option: SelectOption) => option.label}
                    value={values['students_ids']}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(e, value) => setFieldValue('students_ids', value)}
                    renderInput={(params) => (
                      <MuiTextField
                        error={!!errors['students_ids']}
                        helperText={!!errors['students_ids'] ? intl.get('validation.requiredField') : UTILS.BLANK}
                        {...params}
                        label={intl.get('pages.formTeam.students')}
                        placeholder={intl.get('pages.formTeam.studentsPlaceholder')}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  {isSubmitting && <LinearProgress />}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Button
                    variant="outlined"
                    onClick={() => navigate(-1)}
                  >
                    {intl.get('common.cancel')}
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {intl.get('common.save')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
