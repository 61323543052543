/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';

import intl from 'react-intl-universal';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

import { ENDPOINTS, PERMISSIONS, ROUTES, UTILS } from 'shared/constants';
import { initIntl } from 'shared/locales';
import api from 'shared/api';

import { displayError } from 'helpers/http';

import Student from 'models/Student';
import Report from 'models/Report';

import { useAppSelector } from 'store/hooks';

import Table, { Field } from 'components/Table';
import { Type } from 'components/NoResultsBox';
import Breadcrumbs from 'components/Breadcrumbs';

initIntl();

const getColumns = () => {
  return [
    intl.get('pages.reports.id'),
    intl.get('pages.reports.team'),
    intl.get('pages.reports.date'),
  ];
};

const getFields = (): Field[] => {
  return [
    {
      name: 'id',
    },
    {
      name: 'team.name',
    },
    {
      name: 'date',
    },
  ];
};

/**
 * ReportsByStudent component
 * @return {JSX.Element}
 */
function ReportsByStudent(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [reports, setReports] = useState<Report[]>([]);
  const [student, setStudent] = useState<Student>();
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    getReports();
    getStudent();
  }, [id]);

  const fields = useMemo(() => {
    return getFields();
  }, []);

  const columns = useMemo(() => {
    return getColumns();
  }, []);

  const getReports = async () => {
    if (id) {
      const response = await api.get(ENDPOINTS.REPORTS.GET.BY_STUDENT.replace(':studentId', id));
      setReports(response.data);
    }
  };

  const getStudent = async () => {
    if (id) {
      try {
        const response = await api.get(ENDPOINTS.STUDENTS.GET.BY_ID.replace(':id', id));
        setStudent(response.data);
      } catch (error) {
        displayError(error as AxiosError);
        navigate(ROUTES.STUDENTS.replace(':id', id.toString()));
      }
    }
  };

  const viewPath = useMemo(() => {
    if (user) {
      return PERMISSIONS.STUDENTS.VIEW.includes(user.role.id.toString()) ? ROUTES.SPECIAL_TEAMS_STUDENTS_VIEW : undefined;
    }
    return UTILS.BLANK;
  }, [id]);

  const breadcrumbs = useMemo(() => {
    if (id && student) {
      return [
        {
          name: intl.get('pages.students.title'),
          onClickCallback: () => navigate(ROUTES.STUDENTS),
        },
        {
          name: student.name,
          onClickCallback: () => { },
        },
        {
          name: intl.get('pages.reports.title'),
          onClickCallback: () => { },
        },
      ];
    }
    return [];
  }, [id, student]);

  const viewPathResolver = (report: Report) => {
    if (report.team.special) {
      return ROUTES.SPECIAL_TEAMS_STUDENTS_VIEW.replace(':id', report.student.id.toString())
        .replace(':teamId', report.team.id.toString());
    }
    return ROUTES.TEAMS_VIEW_STUDENTS_VIEW.replace(':id', report.student.id.toString())
      .replace(':teamId', report.team.id.toString());
  };

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      {
        user ? <Table
          type={Type.report}
          columns={columns}
          fields={fields}
          onRefresh={getReports}

          filter={{
            data: reports,
            searchMatchFields: ['team.name'],
            searchPlaceholder: intl.get('pages.reports.searchPlaceholder'),
            selects: [],
          }}

          actions={{
            viewPath,
            viewPathResolver,
          }}
        /> : null
      }

    </>
  );
}

export default ReportsByStudent;
