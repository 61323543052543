import React, { CSSProperties, ChangeEvent, useEffect, useState } from 'react';

import { Grid, IconButton, InputLabel, LinearProgress, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import intl from 'react-intl-universal';
import { AxiosError } from 'axios';
import ImageViewer from 'react-simple-image-viewer';

import { displayError } from 'helpers/http';

import api from 'shared/api';
import { UTILS, ENDPOINTS } from 'shared/constants';

import { useToastify } from 'hooks/toastfy';

import { useAppSelector } from 'store/hooks';

import Breadcrumbs from 'components/Breadcrumbs';
import ConditionalContainer from 'components/ConditionalContainer';

const thumbStyle: CSSProperties = {
  borderRadius: '8px',
  cursor: 'pointer',
  marginTop: '16px',
  width: '300px',
  height: '300px',
  display: 'flex',
};

/**
 * ImageFormSchool component
 * @return {JSX.Element}
 */
export default function ImageFormSchool(): JSX.Element {
  const { viewSchoolId, viewSchoolName } = useAppSelector((state) => state.viewSchool);
  const { user } = useAppSelector((state) => state.auth);
  const toast = useToastify();
  const [thumb, setThumb] = useState(UTILS.BLANK);
  const [original, setOriginal] = useState(UTILS.BLANK);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getSchool();
  }, []);

  const breadcrumbs = [{
    name: intl.get('pages.formSchool.editImage'),
    onClickCallback: () => { },
  }];

  const getSchool = async () => {
    if (user?.school || viewSchoolId) {
      const schoolId = user?.school ? user.school.id.toString() : viewSchoolId;
      const response = await api.get(ENDPOINTS.SCHOOLS.GET.BY_ID.replace(':id', schoolId));

      setThumb(response.data.image);
      setOriginal(response.data.image);
    }
  };

  const handleChangeImage = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && (user?.school || (viewSchoolId && viewSchoolName))) {
      const uploadedFile: File = event.target.files[0];
      event.target.value = UTILS.BLANK;
      const url = URL.createObjectURL(uploadedFile);
      setThumb(url);
      setOriginal(url);
      try {
        const schoolId = user?.school ? user.school.id.toString() : viewSchoolId;
        const schoolName = user?.school ? user.school.name : viewSchoolName;

        const FD = new FormData();
        FD.append('image', uploadedFile, uploadedFile.name);
        FD.append('name', schoolName);
        FD.append('_method', 'PUT');
        setIsSubmitting(true);
        await api.post(ENDPOINTS.SCHOOLS.PUT.BY_ID.replace(':id', schoolId), FD);
        setIsSubmitting(false);
        toast.success(intl.get('toast.saveSuccess'));
      } catch (error) {
        setIsSubmitting(false);
        setThumb(UTILS.BLANK);
        setOriginal(UTILS.BLANK);
        displayError(error as AxiosError);
      }
    }
  };

  const handleDelete = async () => {
    if (user?.school || viewSchoolId) {
      try {
        setIsSubmitting(true);
        const schoolId = user?.school ? user.school.id.toString() : viewSchoolId;
        await api.delete(ENDPOINTS.SCHOOLS.DELETE.IMAGE_BY_ID.replace(':id', schoolId));
        setIsSubmitting(false);
        toast.success(intl.get('toast.deleteSuccess'));
        setThumb(UTILS.BLANK);
        setOriginal(UTILS.BLANK);
      } catch (error) {
        setIsSubmitting(false);
        displayError(error as AxiosError);
      }
    } else {
      setThumb(UTILS.BLANK);
    }
  };

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Grid item xs={12}>
        <InputLabel sx={{ mb: 2 }}>{intl.get('pages.formSchool.image')}</InputLabel>
        {
          <>
            <input
              type="file"
              disabled={isSubmitting}
              onChange={(e) => handleChangeImage(e)}
            />
            <Typography mt={1}>{intl.get('pages.formSchool.acceptedFiles')}</Typography>
          </>
        }
      </Grid>
      <ConditionalContainer noComponent checkIf={isSubmitting}>
        <Grid item xs={12} mb={3} mt={1}>
          <Grid item xs={12} md={6}>
            {isSubmitting && <LinearProgress />}
          </Grid>
        </Grid>
      </ConditionalContainer>
      {
        open && (
          <ImageViewer
            backgroundStyle={{ zIndex: 999999 }}
            src={[original]}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={() => setOpen(false)}
          />
        )
      }
      {
        thumb ?
          <Grid container columnSpacing={1} alignItems={'center'}>
            <Grid item xs={'auto'}>
              <img
                onClick={() => setOpen(true)}
                style={thumbStyle}
                src={thumb}
                alt="image"
              />
            </Grid>
            <Grid item xs={'auto'}>
              <IconButton onClick={handleDelete} disabled={isSubmitting}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid> :
          <></>
      }
    </>
  );
}
