import React, { useEffect, useState } from 'react';

import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

import intl from 'react-intl-universal';

import { ROLES, UTILS } from 'shared/constants';

import { useAppSelector } from 'store/hooks';
import { SelectOption } from 'components/Table/Filter';

export interface WeekDevelopment { week: string, score: string }

const scoreOptions: SelectOption[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
];

interface Props {
  value: WeekDevelopment
  index: number
  userBelongsToSubject: boolean
  isSubmiting: boolean
  onSave: (index: number, value: WeekDevelopment) => void
  onChange: (name: string, value: string, index: number) => void
}

/**
 * WeekDevelopmentFields description
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function WeekDevelopmentFields(props: Props): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const [week, setWeek] = useState(UTILS.BLANK);
  const [score, setScore] = useState(UTILS.BLANK);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setIsDirty(false);
    setWeek(props.value.week || UTILS.BLANK);
    setScore(props.value.score || UTILS.BLANK);
  }, [props.value]);

  return <>
    <Typography mb={2}>
      {intl.get('pages.viewStudent.dsde.week', { n: props.index + 1 })}
    </Typography>
    <FormControl fullWidth>
      <TextField
        disabled={(user && user.role.id.toString() === ROLES.TEACHER && !props.userBelongsToSubject) || props.isSubmiting}
        onChange={(e) => {
          setIsDirty(true);
          setWeek(e.target.value);
          props.onChange('week', e.target.value, props.index);
        }}
        InputLabelProps={{ shrink: true }}
        value={props.value.week || UTILS.BLANK}
        label={intl.get('pages.viewStudent.dsde.weekDevelopmentLabel')}
        placeholder={intl.get('pages.viewStudent.dsde.weekDevelopmentPlaceholder')}
        minRows={3}
        error={week.length === 0}
        multiline
      />
    </FormControl>
    <FormControl fullWidth error={!!!score} sx={{ mt: 3, mb: 3 }}>
      <InputLabel id="subject-options-label">{intl.get('pages.viewStudent.map.evaluationLabel')}</InputLabel>
      <Select
        disabled={(user && user.role.id.toString() === ROLES.TEACHER && !props.userBelongsToSubject) || props.isSubmiting}
        labelId="subject-options-label"
        id="subject-options-select"
        value={score?.toString() || UTILS.BLANK}
        label={intl.get('pages.viewStudent.map.evaluationLabel')}
        onChange={(e) => {
          setIsDirty(true);
          setScore(e.target.value);
          props.onChange('score', e.target.value, props.index);
        }}
      >
        {
          scoreOptions.map((subjectOption, index) => <MenuItem key={index} value={subjectOption.value}>
            {subjectOption.label}
          </MenuItem>)
        }
      </Select>
    </FormControl>
  </>;
}
