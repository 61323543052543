import React, { useEffect, useState } from 'react';

import { Backdrop, Box, Button, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useNavigate } from 'react-router-dom';
import * as htmlToImage from 'html-to-image';
import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import api from 'shared/api';
import { ENDPOINTS, ROLES, ROUTES, UTILS } from 'shared/constants';

import { useAppSelector } from 'store/hooks';

import Report, { ReportSubject } from 'models/Report';
import Team from 'models/Team';
import Student from 'models/Student';

import { displayError } from 'helpers/http';

import GoalsChart from './GoalsChart';
import ReportDocument from './Document';
import WeeksDevelopmentChart from './WeeksDevelopmentChart';
import { WeekDevelopment } from 'pages/students/Dsde/WeekDevelopmentFields';

interface Props {
  studentId: string
  teamId: string
  viewer?: boolean
  partial?: boolean
}

export const downloadReportButton = (partial: boolean, disabled: boolean) => <Button
  variant="contained"
  color="primary"
  disabled={disabled}
  sx={{ mb: 2 }}
  startIcon={<DownloadIcon />}
>
  {
    partial ?
      intl.get('components.reportPDF.downloadPartialReport') :
      intl.get('components.reportPDF.downloadFullReport')
  }
</Button>;

/**
 * ReportPDF component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function ReportPDF(props: Props): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const [team, setTeam] = useState<Team>();
  const [student, setStudent] = useState<Student>();
  const [report, setReport] = useState<Report>();
  const [goalsCharts, setGoalsCharts] = useState<Map<number, string>>(new Map());
  const [weeksDevelopmentCharts, setWeeksDevelopmentCharts] = useState<Map<number, string>>(new Map());
  const [images, setImages] = useState<Map<number, string>>(new Map());
  const [schoolImage, setSchoolImage] = useState(UTILS.BLANK);
  const [showPdf, setShowPdf] = useState(false);
  const [start, setStart] = useState(false);

  useEffect(() => {
    if (props.teamId) {
      getTeam();
    }
  }, [props.teamId]);

  useEffect(() => {
    if (props.studentId && team) {
      getStudent();
    }
  }, [props.studentId, team]);

  useEffect(() => {
    if (student && team) {
      getReport();
    }
  }, [student, team]);

  useEffect(() => {
    if (report) {
      setTimeout(() => {
        setStart(true);
      }, 1000);
    }
  }, [report]);

  useEffect(() => {
    setTimeout(() => {
      chartToImage();
    }, 2000);
  }, [start]);

  useEffect(() => {
    if (goalsCharts.size && weeksDevelopmentCharts.size) {
      getReportImages();
    }
  }, [goalsCharts, weeksDevelopmentCharts]);

  useEffect(() => {
    if (props.partial) {
      setShowPdf(true);
    }
  }, [props.partial]);

  const getTeam = async () => {
    if (props.teamId) {
      try {
        const response = await api.get(ENDPOINTS.TEAMS.GET.BY_ID.replace(':id', props.teamId));
        setTeam(response.data);
      } catch (error) {
        displayError(error as AxiosError);
        navigate(ROUTES.TEAMS);
      }
    }
  };

  const getStudent = async () => {
    if (props.studentId && team) {
      try {
        const response = await api.get(ENDPOINTS.STUDENTS.GET.BY_ID.replace(':id', props.studentId));
        setStudent(response.data);
      } catch (error) {
        displayError(error as AxiosError);
        navigate(ROUTES.TEAMS_VIEW.replace(':id', team.id.toString()));
      }
    }
  };

  const getReport = async () => {
    if (student && team) {
      try {
        const studentTeamResponse = await api.get(
          ENDPOINTS.STUDENT_TEAM.GET.BY_STUDENT_ID_AND_TEAM_ID
            .replace(':studentId', student.id.toString())
            .replace(':teamId', team.id.toString()),
        );

        const response = await api.post(ENDPOINTS.REPORTS.POST.ADD, {
          student_team_id: studentTeamResponse.data.id,
          school_id: user && [ROLES.ADMIN, ROLES.AEE].includes(user?.role.id.toString()) ? student.schoolId : undefined,
        });

        setReport(response.data);
      } catch (error) {
        displayError(error as AxiosError);
      }
    }
  };

  const getReportImages = async () => {
    try {
      if (report) {
        await getSchoolImage();

        const newImages: Map<number, string> = new Map();
        for (const reportSubject of report.reportSubjects) {
          if (reportSubject.image) {
            const response = await api.get(ENDPOINTS.REPORT_SUBJECT_IMAGES.GET.BY_ID.replace(':id', reportSubject.image.id.toString()));
            newImages.set(reportSubject.image.id, response.data);
          }
        }
        setImages(newImages);
        setShowPdf(true);
      }
    } catch (error) {
      displayError(error as AxiosError);
    }
  };

  const getSchoolImage = async () => {
    try {
      if (report?.school.image) {
        const response = await api.get(ENDPOINTS.SCHOOLS.GET.IMAGE_BY_ID.replace(':id', report.school.id.toString()));
        setSchoolImage(response.data);
      }
    } catch (error) {
      displayError(error as AxiosError);
    }
  };

  const chartToImage = async () => {
    if (report) {
      const newGoalsCharts: Map<number, string> = new Map();
      const newWeeksDevelopmentChart: Map<number, string> = new Map();
      for (const reportSubject of report.reportSubjects) {
        const goalsChart = document.getElementById(`goalsChart${reportSubject.subjectId}`);
        if (goalsChart) {
          await htmlToImage.toPng(goalsChart).then((dataUrl) => {
            newGoalsCharts.set(reportSubject.subjectId, dataUrl);
          });
        } else {
          newGoalsCharts.set(reportSubject.subjectId, UTILS.BLANK);
        }

        const weeksDevelopmentChart = document.getElementById(`weeksDevelopmentChart${reportSubject.subjectId}`);
        if (weeksDevelopmentChart) {
          await htmlToImage.toPng(weeksDevelopmentChart).then((dataUrl) => {
            newWeeksDevelopmentChart.set(reportSubject.subjectId, dataUrl);
          });
        } else {
          newWeeksDevelopmentChart.set(reportSubject.subjectId, UTILS.BLANK);
        }
      }
      setGoalsCharts(newGoalsCharts);
      setWeeksDevelopmentCharts(newWeeksDevelopmentChart);
    }
  };

  const getWeeksDevelopmentData = (reportSubject: ReportSubject): WeekDevelopment[] => ([
    { week: reportSubject.week1, score: reportSubject.week1Score },
    { week: reportSubject.week2, score: reportSubject.week2Score },
    { week: reportSubject.week3, score: reportSubject.week3Score },
    { week: reportSubject.week4, score: reportSubject.week4Score },
    { week: reportSubject.week5, score: reportSubject.week5Score },
    { week: reportSubject.week6, score: reportSubject.week6Score },
    { week: reportSubject.week7, score: reportSubject.week7Score },
    { week: reportSubject.week8, score: reportSubject.week8Score },
    { week: reportSubject.week9, score: reportSubject.week9Score },
    { week: reportSubject.week10, score: reportSubject.week10Score },
    { week: reportSubject.week11, score: reportSubject.week11Score },
    { week: reportSubject.week12, score: reportSubject.week12Score },
  ]);

  const renderFeedback = () => {
    if (!goalsCharts.size) {
      return <Typography sx={{ color: '#6096ba', fontSize: '35px' }}>
        {intl.get('components.reportPDF.warning')}
      </Typography>;
    }
    if (goalsCharts.size) {
      return <Typography sx={{ color: '#6096ba', fontSize: '35px' }}>
        {intl.get('components.reportPDF.loading')}
      </Typography>;
    }
    if (!images.size && !props.partial) {
      return <Typography sx={{ color: '#6096ba', fontSize: '35px' }}>
        {intl.get('components.reportPDF.gettingVisualRecords')}
      </Typography>;
    }
    if (start) {
      return <Typography sx={{ color: '#6096ba', fontSize: '35px' }}>
        {intl.get('components.reportPDF.buildingReport')}
      </Typography>;
    }
  };

  const renderReport = () => {
    if (report) {
      if (props.viewer) {
        return <PDFViewer style={{ border: 0, height: '100vh', width: '100vw' }}>
          <ReportDocument
            schoolImage={schoolImage}
            partial={props.partial}
            goalsCharts={goalsCharts}
            weeksDevelopmentCharts={weeksDevelopmentCharts}
            images={images}
            report={report}
          />
        </PDFViewer>;
      }
      return <PDFDownloadLink
        style={{ textDecoration: 'none' }}
        fileName={`${report.student.name}${UTILS.DOT}pdf`}
        document={<ReportDocument
          schoolImage={schoolImage}
          partial={props.partial}
          goalsCharts={goalsCharts}
          weeksDevelopmentCharts={weeksDevelopmentCharts}
          images={images}
          report={report}
        />}
      >
        {
          ({ blob, url, loading, error }) => downloadReportButton(props.partial || false, loading)
        }
      </PDFDownloadLink>;
    }
  };

  return <>
    {
      (start && report) && <>
        {
          report.reportSubjects.map((rs, index) => <>
            <GoalsChart
              key={index}
              hide={showPdf}
              goals={rs.goals}
              subjectId={rs.subjectId}
            />
            <WeeksDevelopmentChart
              key={index}
              hide={showPdf}
              weeksDevelopment={getWeeksDevelopmentData(rs)}
              subjectId={rs.subjectId}
            />
          </>)
        }
      </>
    }
    {
      !showPdf && <Backdrop
        id="loading"
        sx={{ color: 'primary', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'white' }}
        open={true}
      >
        <Box p={2}>
          {renderFeedback()}
        </Box>
      </Backdrop>
    }
    {
      renderReport()
    }
  </>;
}
