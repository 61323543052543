import React, { useEffect, useState } from 'react';

import intl from 'react-intl-universal';
import { useNavigate, useParams } from 'react-router-dom';

import { ENDPOINTS, ROUTES } from 'shared/constants';
import { initIntl } from 'shared/locales';
import api from 'shared/api';

import { useAppDispatch } from 'store/hooks';
import { setViewSchoolInfo } from 'store/features/viewSchool/viewSchoolSlice';

import { useToastify } from 'hooks/toastfy';
import School from 'models/School';

initIntl();

/**
 * ViewSchool component
 * @return {JSX.Element}
 */
function ViewSchool(): JSX.Element {
  const [school, setSchool] = useState<School>();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const toast = useToastify();

  useEffect(() => {
    handleGetSchool();
  }, [id]);

  useEffect(() => {
    if (school) {
      dispatch(setViewSchoolInfo({ id, name: school.name }));
      toast.success(intl.get('toast.setViewSchool'));
      navigate(ROUTES.SCHOOLS);
    }
  }, [school]);

  const handleGetSchool = async () => {
    if (id) {
      const response = await api.get(ENDPOINTS.SCHOOLS.GET.BY_ID.replace(':id', id));
      setSchool(response.data);
    }
  };

  return (<></>);
}

export default ViewSchool;
