import { createTheme } from '@mui/material/styles';

import { getThemeLocale } from 'shared/locales';

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    fonts: {
      primary: string,
      secondary: string,
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    fonts?: {
      primary: string,
      secondary: string,
    };
  }
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    logo: React.CSSProperties;
  }

  // allow configuration using `createMuiTheme`
  interface TypographyOptions {
    logo?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    logo: true;
  }
}

const defaultTheme = createTheme({
  typography: {
    h1: {
      fontSize: 18,
      fontWeight: 700,
    },
    h2: {
      fontSize: 16,
      fontWeight: 500,
    },
    h3: {
      fontSize: 14,
      fontWeight: 500,
    },
    h6: {
      fontSize: 10,
      fontWeight: 600,
    },
    body1: {
      fontSize: 14,
      fontWeight: 500,
    },
    caption: {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: '#6096ba',
      light: '#799fb8',
    },
    secondary: {
      main: '#b3b3b3',
      light: '#6200EE',
    },
    text: {
      primary: '#5F6368',
      secondary: '#6096ba',
    },
    error: {
      main: '#B30000',
    },
    success: {
      main: '#408000',
    },
    warning: {
      main: '#FF7F00',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1366,
      xl: 1800,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: ``,
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#FFFFFF',
            backgroundColor: '#6096ba',
          },
        },
      },
    },
  },
}, getThemeLocale());

export default defaultTheme;
